import { API, graphqlOperation } from 'aws-amplify';
import { getIpAccess } from '../../graphql/queries';
import { VALID_API_CALLS_PER_DAY, VALID_API_CALLS_PER_MONTH } from '../../constants/index';
import { fetchDate } from './fetch-date';

export const checkIfIpOverLimit = async (ipAddress: string): Promise<boolean> => {
  const ipAccess: any = await API.graphql(graphqlOperation(getIpAccess, { id: ipAddress }));
  const currentDate = await fetchDate();
  if (!ipAccess.data.getIpAccess) {
    return false;
  }
  const { accessDates } = ipAccess.data.getIpAccess;
  const todaysAttempts = accessDates.filter((date: string) => date === currentDate).length;
  const monthlyAttempts = accessDates.filter((date: string) => date.split('.')[1] === currentDate.split('.')[1]).length;
  if (todaysAttempts >= VALID_API_CALLS_PER_DAY || monthlyAttempts >= VALID_API_CALLS_PER_MONTH) {
    return true;
  }

  return false;
};
