import { Footer as RbiFooter } from '@virtualidentityag/rbi-ui-components';
import React from 'react';
import packageJson from '../../../package.json';
import './footer.scss';

export const Footer: React.FC = () => <RbiFooter className="footer"
bottomLinks={[
  {
    title: 'Impressum',
    href: 'https://www.bausparen.at/de/unternehmen/impressum.html',
  },
  {
    title: 'AGB',
    href: 'https://www.bausparen.at/de/unternehmen/agb.html',
  },
  {
    title: 'Datenschutz',
    href: 'https://www.bausparen.at/de/unternehmen/datenschutz.html',
  },
  {
    title: 'Nutzungsbedingungen',
    href: '/terms-of-use',
  },
  {
    title: 'bausparen.at',
    href: 'https://www.bausparen.at',
  },
  {
    title: 'Cookie-Präferenzen',
    className: 'ot-sdk-show-settings',
    id: 'ot-sdk-btn',
  },
]}
version={packageJson.version}
></RbiFooter>