/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createIpAccess = /* GraphQL */ `
  mutation CreateIpAccess(
    $input: CreateIpAccessInput!
    $condition: ModelIpAccessConditionInput
  ) {
    createIpAccess(input: $input, condition: $condition) {
      id
      accessDates
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateIpAccess = /* GraphQL */ `
  mutation UpdateIpAccess(
    $input: UpdateIpAccessInput!
    $condition: ModelIpAccessConditionInput
  ) {
    updateIpAccess(input: $input, condition: $condition) {
      id
      accessDates
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteIpAccess = /* GraphQL */ `
  mutation DeleteIpAccess(
    $input: DeleteIpAccessInput!
    $condition: ModelIpAccessConditionInput
  ) {
    deleteIpAccess(input: $input, condition: $condition) {
      id
      accessDates
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmailAccess = /* GraphQL */ `
  mutation CreateEmailAccess(
    $input: CreateEmailAccessInput!
    $condition: ModelEmailAccessConditionInput
  ) {
    createEmailAccess(input: $input, condition: $condition) {
      id
      accessDates
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmailAccess = /* GraphQL */ `
  mutation UpdateEmailAccess(
    $input: UpdateEmailAccessInput!
    $condition: ModelEmailAccessConditionInput
  ) {
    updateEmailAccess(input: $input, condition: $condition) {
      id
      accessDates
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmailAccess = /* GraphQL */ `
  mutation DeleteEmailAccess(
    $input: DeleteEmailAccessInput!
    $condition: ModelEmailAccessConditionInput
  ) {
    deleteEmailAccess(input: $input, condition: $condition) {
      id
      accessDates
      createdAt
      updatedAt
      __typename
    }
  }
`;
