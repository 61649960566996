import { API, graphqlOperation } from 'aws-amplify';
import { sha256 } from 'js-sha256';
import { getEmailAccess } from '../../graphql/queries';
import { VALID_API_CALLS_PER_DAY, VALID_API_CALLS_PER_MONTH } from '../../constants';
import { fetchDate } from './fetch-date';
import { removeEmailAlias } from './remove-email-alias';

export const checkIfEmailOverLimit = async (emailAddress: string): Promise<boolean> => {
  const encrypted = sha256(removeEmailAlias(emailAddress));
  const emailAccess: any = await API.graphql(graphqlOperation(getEmailAccess, { id: encrypted }));
  const currentDate = await fetchDate();
  if (!emailAccess.data.getEmailAccess) {
    return false;
  }
  const { accessDates } = emailAccess.data.getEmailAccess;
  const todaysAttempts = accessDates.filter((date: string) => date === currentDate).length;
  const monthlyAttempts = accessDates.filter((date: string) => date.split('.')[1] === currentDate.split('.')[1]).length;
  if (todaysAttempts >= VALID_API_CALLS_PER_DAY || monthlyAttempts >= VALID_API_CALLS_PER_MONTH) {
    return true;
  }

  return false;
};
