import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { App } from './app';
import * as serviceWorker from './serviceWorker';

import { Compose } from './helpers/general/compose';
import { QuestionnaireProvider } from './contexts/questionnaire-context';
import { TrackingProvider } from './contexts/tracking-context';
import { PersonalProvider } from './contexts/personal-context';

ReactDOM.render(
  <Compose
    components={[
      QuestionnaireProvider,
      PersonalProvider,
      TrackingProvider,
    ]}
  >
    <App />
  </Compose>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
