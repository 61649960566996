import React from 'react';

interface ChildComponentProps {
  components: React.JSXElementConstructor<React.PropsWithChildren<any>>[];
}

export const Compose: React.FC<ChildComponentProps> = ({ components, children }) => (
  <React.Fragment>
    {components.reduceRight((acc, Comp) => <Comp>{acc}</Comp>, children)}
  </React.Fragment>
);
