import { sha256 } from 'js-sha256';
import { API, graphqlOperation } from 'aws-amplify';
import { removeEmailAlias } from './remove-email-alias';
import { fetchDate } from './fetch-date';
import { getEmailAccess } from '../../graphql/queries';
import { createEmailAccess, updateEmailAccess } from '../../graphql/mutations';

export const addEmailAttemptToDb = async (emailAddress: string): Promise<void> => {
  const encrypted = sha256(removeEmailAlias(emailAddress));
  const currentDate = await fetchDate();
  const emailAccess: any = await API.graphql(graphqlOperation(getEmailAccess, { id: encrypted }));
  if (!emailAccess.data.getEmailAccess) {
    await API.graphql(graphqlOperation(createEmailAccess, { input: { id: encrypted, accessDates: [currentDate] } }));
  } else {
    const { accessDates } = emailAccess.data.getEmailAccess;
    await API.graphql(graphqlOperation(updateEmailAccess, { input: { id: encrypted, accessDates: [...accessDates, currentDate] } }));
  }
};
