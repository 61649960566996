import { API, graphqlOperation } from 'aws-amplify';
import { ImmazingApiInput, ImmazingApiResult } from '../../interfaces/immazing-api';
import { getPropertyValuation } from '../../graphql/queries';

export const fetchPropertyValuation = async (propertyInformation: ImmazingApiInput): Promise<ImmazingApiResult> => {
  const result: any = await API.graphql(graphqlOperation(getPropertyValuation, {
    propertyInformation: JSON.stringify(propertyInformation),
  }));

  const parsed = JSON.parse(result.data.getPropertyValuation);

  if (parsed.statusCode !== 200) {
    throw new Error(parsed.message);
  }

  return JSON.parse(result.data.getPropertyValuation).body;
};
