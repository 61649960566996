import pdfMake, { TCreatedPdf } from 'pdfmake/build/pdfmake';
import { Content } from 'pdfmake/interfaces';

export interface ResultData {
  calculationDate: string;
  categories: ResultCategory[];
}

interface ResultCategory {
  headline: string;
  parameters: ResultParameter[];
}

interface ResultParameter {
  key: string;
  value: string;
  bold?: boolean;
}

const CTA_HREF = 'https://wohntraumrechner.raiffeisen.at/?utm_source=immocheck-output-pdf&utm_medium=clickout&utm_campaign=immocheck-2020&utm_content=link';

const GLOBAL_LABELS = {
  headline: 'Schätzwert Ihrer Wunschimmobilie',
  subheadline: 'Der ImmoCheck der Raiffeisen Bausparkasse',
  date: 'Bewertungsdatum:',
  footerHeadline: 'Raiffeisen Bausparkasse Gesellschaft m.b.H.',
  footerContent: 'Mooslackengasse 12, 1190 Wien | t (01) 546 46-0 oder DW | f (01) 546 46-2369 oder DW | www.bausparen.at | FN 116309v | Handelsgericht Wien Informationen zum Datenschutz: www.bausparen.at/datenschutz',
  cta: 'ZUM WOHNTRAUMRECHNER',
  disclaimer: 'Der angegebene Schätzwert basiert (ausgehend von den vom Nutzer gemachten Angaben) auf aktuellen Marktdaten. Abweichungen innerhalb der Preisspanne können jedoch nicht ausgeschlossen werden. Die Raiffeisen Bausparkasse übernimmt keine Gewähr, Haftung oder Verantwortung dafür, dass die im Rahmen des ImmoCheck ermittelten und/oder im Ergebnisdokument enthaltenen Daten, Preise und Werte vollständig zutreffend sind oder tatsächlich bestimmte Kaufpreise auf dem Markt erzielt werden können. Mehr Informationen zur Bewertung finden Sie in den ImmoCheck Nutzungsbedingungen.',
};

const logoSvg = `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="200px" height="58px" viewBox="0 0 200 58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<!-- Generator: Sketch 56.3 (81716) - https://sketch.com -->
<title>logo-raiffeisen-bausparkasse</title>
<desc>Created with Sketch.</desc>
<g id="logo-raiffeisen-bausparkasse" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
<g id="Group-31" transform="translate(0.000000, 2.000000)">
<path d="M146.894393,51.6682243 L146.894393,-0.110280374 L198.673832,-0.110280374 L198.673832,51.6682243 L146.894393,51.6682243 Z" id="Fill-2" stroke="#000000" stroke-width="2" fill="#FBF315"></path>
<g id="Group-30" transform="translate(0.299065, 3.539252)">
    <path d="M172.474673,28.267757 L188.368131,44.1443925 L194.577477,37.9359813 L178.687757,22.0584112 L181.779346,18.9705607 L181.761589,12.7621495 L183.973738,10.5481308 L183.99243,15.6696262 L186.138224,17.8116822 L193.909252,10.0434579 L195.988692,12.121028 C195.988692,10.7864486 195.612056,6.17616822 191.916729,2.57616822 C187.779346,-1.45654206 183.63729,0.90046729 182.212056,2.32663551 L174.712991,9.82850467 L176.584953,11.6939252 L172.446636,15.8070093 L168.319533,11.6883178 L170.183084,9.82663551 L162.686822,2.32663551 C161.258785,0.90046729 157.118598,-1.45654206 152.98215,2.57616822 C149.287757,6.17616822 148.98028,10.778972 148.98028,12.114486 L151.01486,10.0752336 L158.764393,17.8191589 L160.92514,15.6556075 L160.92514,10.5481308 L163.138224,12.7621495 L163.133551,18.9369159 L166.233551,22.0341121 L150.48215,37.7714953 L156.723271,44.0051402 L172.474673,28.267757" id="Fill-3" fill="#020203"></path>
    <path d="M45.9093458,20.6642991 L46.264486,20.6642991 C47.0121495,20.6642991 47.6224299,20.5456075 48.0962617,20.3091589 C48.5700935,20.0727103 48.8065421,19.6054206 48.8065421,18.9072897 C48.8065421,18.222243 48.5728972,17.757757 48.1056075,17.5147664 C47.6383178,17.2717757 47.0252336,17.1502804 46.264486,17.1502804 L45.9093458,17.1502804 L45.9093458,20.6642991 Z M53.9654206,28.4390654 L49.4056075,28.4390654 L45.946729,23.0194393 L45.9093458,28.4390654 L42.2457944,28.4390654 L42.2457944,14.3465421 L47.7224299,14.3465421 C49.2056075,14.3465421 50.3915888,14.704486 51.2831776,15.4213084 C52.1738318,16.1381308 52.6196262,17.2250467 52.6196262,18.6829907 C52.6196262,19.6923364 52.3485981,20.5642991 51.8065421,21.2998131 C51.264486,22.0353271 50.5074766,22.4895327 49.535514,22.6642991 L53.9654206,28.4390654 L53.9654206,28.4390654 Z" id="Fill-5" fill="#000000"></path>
    <path d="M57.6103738,23.6542056 C57.6103738,24.2906542 57.8038318,24.8074766 58.1898131,25.2056075 C58.5757944,25.6046729 59.1113084,25.8046729 59.7972897,25.8046729 C60.4701869,25.8046729 61.0028972,25.6018692 61.3954206,25.1971963 C61.7888785,24.7915888 61.9851402,24.2775701 61.9851402,23.6542056 C61.9851402,23.0317757 61.7907477,22.5149533 61.4047664,22.1037383 C61.0178505,21.6925234 60.4823364,21.4869159 59.7972897,21.4869159 C59.1243925,21.4869159 58.5916822,21.6953271 58.1991589,22.1130841 C57.8066355,22.5308411 57.6103738,23.0448598 57.6103738,23.6542056 M65.2739252,28.4392523 L61.8720561,28.4392523 L61.8720561,27.3747664 C61.5599065,27.835514 61.1430841,28.1813084 60.6206542,28.4121495 C60.0963551,28.6420561 59.5356075,28.7570093 58.9374766,28.7570093 C57.5542991,28.7570093 56.4019626,28.2542056 55.4804673,27.246729 C54.5571028,26.2392523 54.0963551,25.0392523 54.0963551,23.6448598 C54.0963551,22.2401869 54.5458879,21.0401869 55.4430841,20.0448598 C56.3402804,19.0495327 57.4916822,18.5523364 58.9000935,18.5523364 C59.4860748,18.5523364 60.0402804,18.653271 60.5636449,18.8560748 C61.0879439,19.0588785 61.5225234,19.3682243 61.8720561,19.7859813 L61.8720561,18.8514019 L65.2739252,18.8514019 L65.2739252,28.4392523" id="Fill-7" fill="#000000"></path>
    <path d="M67.4411215,28.4392523 L70.8429907,28.4392523 L70.8429907,18.8514019 L67.4411215,18.8514019 L67.4411215,28.4392523 Z M71.0485981,15.1691589 C71.0485981,15.6925234 70.8626168,16.1411215 70.4878505,16.5149533 C70.1140187,16.888785 69.6654206,17.0757009 69.1420561,17.0757009 C68.6186916,17.0757009 68.171028,16.888785 67.7962617,16.5149533 C67.4224299,16.1411215 67.2364486,15.6925234 67.2364486,15.1691589 C67.2364486,14.6457944 67.4224299,14.1971963 67.7962617,13.8233645 C68.171028,13.4495327 68.6186916,13.2626168 69.1420561,13.2626168 C69.6654206,13.2626168 70.1140187,13.4495327 70.4878505,13.8233645 C70.8626168,14.1971963 71.0485981,14.6457944 71.0485981,15.1691589 L71.0485981,15.1691589 Z" id="Fill-9" fill="#000000"></path>
    <path d="M76.7317757,28.4393458 L73.328972,28.4393458 L73.328972,21.6552336 L72.1336449,21.6552336 L72.1336449,18.8514953 L73.328972,18.8514953 L73.328972,17.6365421 C73.328972,16.8262617 73.382243,16.1842056 73.488785,15.7113084 C73.5934579,15.2374766 73.9747664,14.6243925 74.628972,13.8701869 C75.2831776,13.1159813 76.288785,12.7393458 77.646729,12.7393458 C78.1831776,12.7393458 78.6691589,12.8514953 79.1056075,13.0757944 L79.1056075,16.0103738 C78.8551402,15.8982243 78.6476636,15.8169159 78.4794393,15.7673832 C78.3102804,15.7169159 78.1205607,15.6926168 77.9093458,15.6926168 C77.4850467,15.6926168 77.1831776,15.8206542 77.0028037,16.0757944 C76.8224299,16.3309346 76.7317757,16.7393458 76.7317757,17.3000935 L76.7317757,18.8514953 L79.1056075,18.8514953 L79.1056075,21.6552336 L76.7317757,21.6552336 L76.7317757,28.4393458" id="Fill-11" fill="#000000"></path>
    <path d="M83.7037383,28.4393458 L80.3009346,28.4393458 L80.3009346,21.6552336 L79.1056075,21.6552336 L79.1056075,18.8514953 L80.3009346,18.8514953 L80.3009346,17.6365421 C80.3009346,16.8262617 80.3542056,16.1842056 80.4607477,15.7113084 C80.5654206,15.2374766 80.946729,14.6243925 81.6009346,13.8701869 C82.2551402,13.1159813 83.2607477,12.7393458 84.6186916,12.7393458 C85.1551402,12.7393458 85.6411215,12.8514953 86.0775701,13.0757944 L86.0775701,16.0103738 C85.8271028,15.8982243 85.6196262,15.8169159 85.4514019,15.7673832 C85.282243,15.7169159 85.0925234,15.6926168 84.8813084,15.6926168 C84.4570093,15.6926168 84.1551402,15.8206542 83.9747664,16.0757944 C83.7934579,16.3309346 83.7037383,16.7393458 83.7037383,17.3000935 L83.7037383,18.8514953 L86.0775701,18.8514953 L86.0775701,21.6552336 L83.7037383,21.6552336 L83.7037383,28.4393458" id="Fill-12" fill="#000000"></path>
    <path d="M93.6281308,22.2345794 C93.5402804,21.7850467 93.3159813,21.4261682 92.9542991,21.1588785 C92.5935514,20.8915888 92.1757944,20.7579439 91.7019626,20.7579439 C91.1795327,20.7579439 90.7365421,20.882243 90.3748598,21.1317757 C90.0141121,21.3813084 89.783271,21.7485981 89.683271,22.2345794 L93.6281308,22.2345794 Z M96.7870093,24.1962617 L89.6281308,24.1962617 C89.6281308,25.6299065 90.3374766,26.3457944 91.758972,26.3457944 C92.1449533,26.3457944 92.465514,26.2785047 92.7206542,26.1401869 C92.976729,26.0037383 93.1982243,25.7794393 93.3842056,25.4682243 L96.6552336,25.4682243 C96.394486,26.5392523 95.8085047,27.3551402 94.8982243,27.9168224 C93.9888785,28.4766355 92.947757,28.7570093 91.776729,28.7570093 C90.1328037,28.7570093 88.7963551,28.3102804 87.7673832,27.4149533 C86.7402804,26.5196262 86.2262617,25.2691589 86.2262617,23.664486 C86.2262617,22.0850467 86.7141121,20.8373832 87.6926168,19.9233645 C88.6711215,19.0093458 89.9514953,18.5523364 91.5346729,18.5523364 C93.1785981,18.5523364 94.465514,19.0261682 95.394486,19.9728972 C96.3225234,20.9196262 96.7870093,22.2102804 96.7870093,23.8439252 L96.7870093,24.1962617 L96.7870093,24.1962617 Z" id="Fill-13" fill="#000000"></path>
    <path d="M98.2439252,28.4392523 L101.645794,28.4392523 L101.645794,18.8514019 L98.2439252,18.8514019 L98.2439252,28.4392523 Z M101.851402,15.1691589 C101.851402,15.6925234 101.665421,16.1411215 101.290654,16.5149533 C100.916822,16.888785 100.468224,17.0757009 99.9448598,17.0757009 C99.4214953,17.0757009 98.9738318,16.888785 98.5990654,16.5149533 C98.2252336,16.1411215 98.0392523,15.6925234 98.0392523,15.1691589 C98.0392523,14.6457944 98.2252336,14.1971963 98.5990654,13.8233645 C98.9738318,13.4495327 99.4214953,13.2626168 99.9448598,13.2626168 C100.468224,13.2626168 100.916822,13.4495327 101.290654,13.8233645 C101.665421,14.1971963 101.851402,14.6457944 101.851402,15.1691589 L101.851402,15.1691589 Z" id="Fill-14" fill="#000000"></path>
    <path d="M110.132523,21.3186916 C109.546542,21.0196262 108.936262,20.8700935 108.299813,20.8700935 C108.039065,20.8700935 107.796075,20.9224299 107.571776,21.028972 C107.348411,21.1336449 107.235327,21.2990654 107.235327,21.5224299 C107.235327,21.8084112 107.348411,21.9915888 107.571776,22.0728972 C107.796075,22.153271 108.27271,22.2654206 109.000748,22.4084112 C109.72972,22.5514019 110.338131,22.8429907 110.823178,23.2850467 C111.310093,23.7252336 111.554019,24.3626168 111.554019,25.1962617 C111.554019,26.3766355 111.10729,27.2654206 110.216636,27.8626168 C109.325047,28.4588785 108.263364,28.7570093 107.030654,28.7570093 C106.343738,28.7570093 105.677383,28.664486 105.028785,28.4766355 C104.382056,28.2897196 103.759626,28.0158879 103.159626,27.6542056 L104.393271,25.2803738 C104.768037,25.5439252 105.20729,25.7700935 105.712897,25.9635514 C106.21757,26.1560748 106.674579,26.2523364 107.08486,26.2523364 C107.334393,26.2523364 107.575514,26.1906542 107.805421,26.0663551 C108.035327,25.9420561 108.151215,25.7616822 108.151215,25.5252336 C108.151215,25.2018692 107.933458,24.9654206 107.497944,24.8168224 C106.861495,24.6682243 106.29514,24.5280374 105.79514,24.3971963 C105.297009,24.2663551 104.855888,24.0214953 104.468972,23.6607477 C104.082056,23.3009346 103.888598,22.728972 103.888598,21.9457944 C103.888598,20.8523364 104.30729,20.0130841 105.140935,19.428972 C105.976449,18.8448598 106.966168,18.5523364 108.113832,18.5523364 C108.698879,18.5523364 109.247477,18.5990654 109.759626,18.6925234 C110.269907,18.7859813 110.774579,18.9448598 111.27271,19.1691589 L110.132523,21.3186916" id="Fill-15" fill="#000000"></path>
    <path d="M119.98215,22.2345794 C119.894299,21.7850467 119.67,21.4261682 119.308318,21.1588785 C118.948505,20.8915888 118.529813,20.7579439 118.056916,20.7579439 C117.533551,20.7579439 117.090561,20.882243 116.730748,21.1317757 C116.368131,21.3813084 116.13729,21.7485981 116.03729,22.2345794 L119.98215,22.2345794 Z M123.141028,24.1962617 L115.98215,24.1962617 C115.98215,25.6299065 116.69243,26.3457944 118.112991,26.3457944 C118.498972,26.3457944 118.819533,26.2785047 119.074673,26.1401869 C119.330748,26.0037383 119.552243,25.7794393 119.738224,25.4682243 L123.009252,25.4682243 C122.748505,26.5392523 122.162523,27.3551402 121.253178,27.9168224 C120.342897,28.4766355 119.30271,28.7570093 118.132617,28.7570093 C116.486822,28.7570093 115.150374,28.3102804 114.122336,27.4149533 C113.094299,26.5196262 112.58028,25.2691589 112.58028,23.664486 C112.58028,22.0850467 113.07,20.8373832 114.04757,19.9233645 C115.02514,19.0093458 116.306449,18.5523364 117.888692,18.5523364 C119.534486,18.5523364 120.820467,19.0261682 121.749439,19.9728972 C122.676542,20.9196262 123.141028,22.2102804 123.141028,23.8439252 L123.141028,24.1962617 L123.141028,24.1962617 Z" id="Fill-16" fill="#000000"></path>
    <path d="M128.000841,20.0851402 C128.412991,19.518785 128.854112,19.1215888 129.327944,18.8935514 C129.801776,18.6664486 130.395234,18.5524299 131.104579,18.5524299 C132.338224,18.5524299 133.24757,18.9038318 133.832617,19.6066355 C134.418598,20.3094393 134.711121,21.2823364 134.711121,22.5262617 L134.711121,28.4393458 L131.309252,28.4393458 L131.309252,23.3374766 C131.309252,22.7141121 131.213925,22.2094393 131.020467,21.8234579 C130.827009,21.4374766 130.412056,21.2440187 129.777477,21.2440187 C129.128879,21.2440187 128.670935,21.4468224 128.40271,21.8514953 C128.136355,22.2561682 128.000841,22.7954206 128.000841,23.4683178 L128.000841,28.4393458 L124.599907,28.4393458 L124.599907,18.8514953 L128.000841,18.8514953 L128.000841,20.0851402" id="Fill-17" fill="#000000"></path>
    <path d="M4.26542056,44.3271963 L4.73271028,44.3271963 C5.62990654,44.3271963 6.33925234,44.2496262 6.8635514,44.0935514 C7.38691589,43.9374766 7.64859813,43.5178505 7.64859813,42.8328037 C7.64859813,42.1842056 7.4271028,41.7664486 6.98504673,41.5795327 C6.54205607,41.3935514 5.9411215,41.2991589 5.18037383,41.2991589 L4.26542056,41.2991589 L4.26542056,44.3271963 Z M4.26542056,38.5701869 L4.67570093,38.5701869 C5.27476636,38.5701869 5.75420561,38.4804673 6.11495327,38.2991589 C6.47663551,38.1178505 6.65700935,37.7542991 6.65700935,37.2057009 C6.65700935,36.6328037 6.4635514,36.2617757 6.07757009,36.0935514 C5.69158879,35.9253271 5.17383178,35.841215 4.5271028,35.841215 L4.26542056,35.841215 L4.26542056,38.5701869 Z M0.601869159,47.1309346 L0.601869159,33.0374766 L5.83457944,33.0374766 C7.15607477,33.0374766 8.18411215,33.3271963 8.91869159,33.9066355 C9.65420561,34.4860748 10.0224299,35.4178505 10.0224299,36.701028 C10.0224299,37.3870093 9.90373832,37.9664486 9.66728972,38.4402804 C9.42990654,38.9131776 9.03084112,39.2935514 8.47102804,39.5795327 C9.44299065,39.7290654 10.164486,40.1159813 10.6383178,40.7393458 C11.1121495,41.3627103 11.3495327,42.1842056 11.3495327,43.2057009 C11.3495327,44.576729 10.8654206,45.5729907 9.90093458,46.1963551 C8.93457944,46.8197196 7.71682243,47.1309346 6.24672897,47.1309346 L0.601869159,47.1309346 L0.601869159,47.1309346 Z" id="Fill-18" fill="#000000"></path>
    <path d="M15.9101869,42.3457944 C15.9101869,42.9813084 16.1036449,43.4990654 16.4896262,43.8971963 C16.8756075,44.2962617 17.4111215,44.4953271 18.0971028,44.4953271 C18.77,44.4953271 19.3027103,44.2934579 19.6952336,43.888785 C20.087757,43.4831776 20.2840187,42.9691589 20.2840187,42.3457944 C20.2840187,41.7233645 20.0905607,41.2065421 19.7045794,40.7943925 C19.3176636,40.3841121 18.7821495,40.1775701 18.0971028,40.1775701 C17.4242056,40.1775701 16.8914953,40.3859813 16.498972,40.8046729 C16.1064486,41.2214953 15.9101869,41.735514 15.9101869,42.3457944 M23.5737383,47.1308411 L20.1718692,47.1308411 L20.1718692,46.0663551 C19.8597196,46.5271028 19.4428972,46.8728972 18.9195327,47.1028037 C18.3961682,47.3336449 17.8354206,47.4485981 17.2372897,47.4485981 C15.8541121,47.4485981 14.7017757,46.9448598 13.7793458,45.9373832 C12.8569159,44.9299065 12.3961682,43.7299065 12.3961682,42.3364486 C12.3961682,40.9308411 12.8447664,39.7308411 13.7419626,38.7364486 C14.6391589,37.7411215 15.7914953,37.2429907 17.1999065,37.2429907 C17.7858879,37.2429907 18.3400935,37.3448598 18.8634579,37.546729 C19.3868224,37.7495327 19.8223364,38.0588785 20.1718692,38.4775701 L20.1718692,37.5429907 L23.5737383,37.5429907 L23.5737383,47.1308411" id="Fill-19" fill="#000000"></path>
    <path d="M29.0869159,37.543271 L29.0869159,42.6638318 C29.0869159,43.3488785 29.2046729,43.8666355 29.4420561,44.2152336 C29.6785047,44.5647664 30.1280374,44.7385981 30.7878505,44.7385981 C31.435514,44.7385981 31.8813084,44.5582243 32.1242991,44.1974766 C32.3672897,43.8348598 32.488785,43.3245794 32.488785,42.6638318 L32.488785,37.543271 L35.8906542,37.543271 L35.8906542,43.3180374 C35.8906542,44.8124299 35.4383178,45.8759813 34.535514,46.5049533 C33.6327103,47.1339252 32.3831776,47.4488785 30.7878505,47.4488785 C29.217757,47.4488785 27.9747664,47.1311215 27.0588785,46.4965421 C26.1429907,45.8591589 25.6850467,44.801215 25.6850467,43.3180374 L25.6850467,37.543271 L29.0869159,37.543271" id="Fill-20" fill="#000000"></path>
    <path d="M44.3026168,40.0093458 C43.7166355,39.7102804 43.1054206,39.5616822 42.4699065,39.5616822 C42.2082243,39.5616822 41.9661682,39.6140187 41.7409346,39.7196262 C41.5166355,39.8252336 41.404486,39.9906542 41.404486,40.2140187 C41.404486,40.5 41.5166355,40.6831776 41.7409346,40.7635514 C41.9661682,40.8448598 42.4418692,40.9570093 43.1708411,41.0990654 C43.8998131,41.2420561 44.5072897,41.5345794 44.993271,41.9766355 C45.4792523,42.4168224 45.722243,43.0542056 45.722243,43.8878505 C45.722243,45.0682243 45.2773832,45.9579439 44.3857944,46.5542056 C43.4942056,47.1504673 42.4334579,47.4485981 41.1988785,47.4485981 C40.5138318,47.4485981 39.8465421,47.3560748 39.1988785,47.1682243 C38.5502804,46.9813084 37.9278505,46.7074766 37.3297196,46.346729 L38.5633645,43.9719626 C38.9371963,44.2345794 39.3764486,44.4616822 39.8811215,44.6542056 C40.3857944,44.8476636 40.8437383,44.9448598 41.2549533,44.9448598 C41.5035514,44.9448598 41.7437383,44.882243 41.9745794,44.7579439 C42.204486,44.6327103 42.3203738,44.453271 42.3203738,44.2168224 C42.3203738,43.8934579 42.1026168,43.6570093 41.6661682,43.5084112 C41.0315888,43.3598131 40.4633645,43.2196262 39.9652336,43.088785 C39.4671028,42.9588785 39.0241121,42.7130841 38.6381308,42.3523364 C38.2521495,41.9915888 38.0586916,41.4205607 38.0586916,40.6364486 C38.0586916,39.5429907 38.475514,38.7037383 39.311028,38.1196262 C40.1456075,37.535514 41.1362617,37.2429907 42.2829907,37.2429907 C42.8680374,37.2429907 43.4166355,37.2897196 43.9278505,37.3841121 C44.4390654,37.4766355 44.9437383,37.635514 45.4418692,37.8598131 L44.3026168,40.0093458" id="Fill-21" fill="#000000"></path>
    <path d="M50.6374766,42.3549533 C50.6374766,42.9895327 50.8309346,43.5035514 51.2169159,43.8998131 C51.6028972,44.2970093 52.1393458,44.4951402 52.8253271,44.4951402 C53.4972897,44.4951402 54.0309346,44.2942056 54.4234579,43.8914019 C54.8159813,43.4876636 55.012243,42.975514 55.012243,42.3549533 C55.012243,41.7353271 54.818785,41.2203738 54.4318692,40.8119626 C54.0449533,40.4007477 53.5094393,40.1960748 52.8253271,40.1960748 C52.1514953,40.1960748 51.6197196,40.404486 51.2271963,40.8194393 C50.8337383,41.2353271 50.6374766,41.7484112 50.6374766,42.3549533 M50.7496262,38.6278505 C51.0608411,38.1839252 51.4851402,37.8428037 52.0206542,37.6026168 C52.5571028,37.3633645 53.1103738,37.2428037 53.6851402,37.2428037 C55.0926168,37.2428037 56.2514953,37.7437383 57.1608411,38.7465421 C58.0701869,39.7465421 58.5253271,40.9558879 58.5253271,42.3745794 C58.5253271,43.7792523 58.0739252,44.9783178 57.1701869,45.9652336 C56.2664486,46.9549533 55.1178505,47.4484112 53.7225234,47.4484112 C53.147757,47.4484112 52.5972897,47.3465421 52.0673832,47.14 C51.5384112,46.9343925 51.0982243,46.6259813 50.7496262,46.2147664 L50.7496262,51.9343925 L47.347757,51.9343925 L47.347757,37.5437383 L50.7496262,37.5437383 L50.7496262,38.6278505" id="Fill-22" fill="#000000"></path>
    <path d="M62.9362617,42.3457944 C62.9362617,42.9813084 63.1297196,43.4990654 63.5157009,43.8971963 C63.9016822,44.2962617 64.4381308,44.4953271 65.1231776,44.4953271 C65.7970093,44.4953271 66.328785,44.2934579 66.7213084,43.888785 C67.1147664,43.4831776 67.311028,42.9691589 67.311028,42.3457944 C67.311028,41.7233645 67.1175701,41.2065421 66.7315888,40.7943925 C66.3446729,40.3841121 65.8082243,40.1775701 65.1231776,40.1775701 C64.4502804,40.1775701 63.9175701,40.3859813 63.5250467,40.8046729 C63.1325234,41.2214953 62.9362617,41.735514 62.9362617,42.3457944 M70.5998131,47.1308411 L67.1988785,47.1308411 L67.1988785,46.0663551 C66.886729,46.5271028 66.468972,46.8728972 65.9465421,47.1028037 C65.422243,47.3336449 64.8624299,47.4485981 64.2633645,47.4485981 C62.8801869,47.4485981 61.7278505,46.9448598 60.8063551,45.9373832 C59.8839252,44.9299065 59.422243,43.7299065 59.422243,42.3364486 C59.422243,40.9308411 59.8717757,39.7308411 60.768972,38.7364486 C61.6661682,37.7411215 62.8175701,37.2429907 64.2259813,37.2429907 C64.8119626,37.2429907 65.3671028,37.3448598 65.8895327,37.546729 C66.4138318,37.7495327 66.8493458,38.0588785 67.1988785,38.4775701 L67.1988785,37.5429907 L70.5998131,37.5429907 L70.5998131,47.1308411" id="Fill-23" fill="#000000"></path>
    <path d="M76.1700935,39.1121495 C76.5074766,38.5392523 76.9429907,38.1121495 77.4794393,37.8317757 C78.0149533,37.5514019 78.6252336,37.411215 79.311215,37.411215 L79.9654206,37.411215 L79.9654206,40.682243 C79.7411215,40.5579439 79.5224299,40.4738318 79.311215,40.4299065 C79.0981308,40.3859813 78.8373832,40.364486 78.5261682,40.364486 C77.6411215,40.364486 77.0271028,40.617757 76.6841121,41.1224299 C76.3420561,41.6271028 76.1700935,42.3392523 76.1700935,43.2626168 L76.1700935,47.1308411 L72.7691589,47.1308411 L72.7691589,37.5429907 L76.1700935,37.5429907 L76.1700935,39.1121495" id="Fill-24" fill="#000000"></path>
    <polygon id="Fill-25" fill="#000000" points="84.5428972 41.0004673 88.0765421 37.5434579 92.7298131 37.5434579 87.9260748 41.9724299 93.0288785 47.1313084 88.2634579 47.1313084 84.5428972 43.2257009 84.5428972 47.1313084 81.1419626 47.1313084 81.1419626 31.6182243 84.5428972 31.6182243"></polygon>
    <path d="M96.7299065,42.3457944 C96.7299065,42.9813084 96.9233645,43.4990654 97.3093458,43.8971963 C97.6953271,44.2962617 98.2317757,44.4953271 98.9168224,44.4953271 C99.5906542,44.4953271 100.12243,44.2934579 100.514953,43.888785 C100.908411,43.4831776 101.104673,42.9691589 101.104673,42.3457944 C101.104673,41.7233645 100.911215,41.2065421 100.525234,40.7943925 C100.138318,40.3841121 99.6028037,40.1775701 98.9168224,40.1775701 C98.2439252,40.1775701 97.711215,40.3859813 97.3186916,40.8046729 C96.9261682,41.2214953 96.7299065,41.735514 96.7299065,42.3457944 M104.393458,47.1308411 L100.992523,47.1308411 L100.992523,46.0663551 C100.680374,46.5271028 100.263551,46.8728972 99.7401869,47.1028037 C99.2158879,47.3336449 98.6560748,47.4485981 98.0570093,47.4485981 C96.6747664,47.4485981 95.5214953,46.9448598 94.6,45.9373832 C93.6775701,44.9299065 93.2168224,43.7299065 93.2168224,42.3364486 C93.2168224,40.9308411 93.6654206,39.7308411 94.5626168,38.7364486 C95.4598131,37.7411215 96.611215,37.2429907 98.0196262,37.2429907 C98.6056075,37.2429907 99.1607477,37.3448598 99.6831776,37.546729 C100.207477,37.7495327 100.642991,38.0588785 100.992523,38.4775701 L100.992523,37.5429907 L104.393458,37.5429907 L104.393458,47.1308411" id="Fill-26" fill="#000000"></path>
    <path d="M112.897944,40.0093458 C112.311963,39.7102804 111.702617,39.5616822 111.065234,39.5616822 C110.804486,39.5616822 110.561495,39.6140187 110.337196,39.7196262 C110.113832,39.8252336 110.000748,39.9906542 110.000748,40.2140187 C110.000748,40.5 110.113832,40.6831776 110.337196,40.7635514 C110.561495,40.8448598 111.038131,40.9570093 111.766168,41.0990654 C112.49514,41.2420561 113.104486,41.5345794 113.588598,41.9766355 C114.075514,42.4168224 114.319439,43.0542056 114.319439,43.8878505 C114.319439,45.0682243 113.87271,45.9579439 112.982056,46.5542056 C112.090467,47.1504673 111.028785,47.4485981 109.796075,47.4485981 C109.109159,47.4485981 108.442804,47.3560748 107.794206,47.1682243 C107.147477,46.9813084 106.525047,46.7074766 105.925047,46.346729 L107.158692,43.9719626 C107.533458,44.2345794 107.97271,44.4616822 108.478318,44.6542056 C108.982991,44.8476636 109.44,44.9448598 109.85028,44.9448598 C110.099813,44.9448598 110.340935,44.882243 110.570841,44.7579439 C110.800748,44.6327103 110.916636,44.453271 110.916636,44.2168224 C110.916636,43.8934579 110.698879,43.6570093 110.263364,43.5084112 C109.626916,43.3598131 109.060561,43.2196262 108.560561,43.088785 C108.06243,42.9588785 107.621308,42.7130841 107.234393,42.3523364 C106.847477,41.9915888 106.654019,41.4205607 106.654019,40.6364486 C106.654019,39.5429907 107.07271,38.7037383 107.906355,38.1196262 C108.741869,37.535514 109.731589,37.2429907 110.879252,37.2429907 C111.464299,37.2429907 112.012897,37.2897196 112.525047,37.3841121 C113.035327,37.4766355 113.54,37.635514 114.039065,37.8598131 L112.897944,40.0093458" id="Fill-27" fill="#000000"></path>
    <path d="M122.393364,40.0093458 C121.807383,39.7102804 121.197103,39.5616822 120.560654,39.5616822 C120.299907,39.5616822 120.056916,39.6140187 119.832617,39.7196262 C119.609252,39.8252336 119.496168,39.9906542 119.496168,40.2140187 C119.496168,40.5 119.609252,40.6831776 119.832617,40.7635514 C120.056916,40.8448598 120.533551,40.9570093 121.261589,41.0990654 C121.991495,41.2420561 122.598972,41.5345794 123.084953,41.9766355 C123.570935,42.4168224 123.81486,43.0542056 123.81486,43.8878505 C123.81486,45.0682243 123.368131,45.9579439 122.477477,46.5542056 C121.585888,47.1504673 120.524206,47.4485981 119.290561,47.4485981 C118.605514,47.4485981 117.938224,47.3560748 117.289626,47.1682243 C116.641963,46.9813084 116.019533,46.7074766 115.420467,46.346729 L116.655047,43.9719626 C117.028879,44.2345794 117.468131,44.4616822 117.973738,44.6542056 C118.478411,44.8476636 118.935421,44.9448598 119.346636,44.9448598 C119.595234,44.9448598 119.835421,44.882243 120.066262,44.7579439 C120.296168,44.6327103 120.412056,44.453271 120.412056,44.2168224 C120.412056,43.8934579 120.194299,43.6570093 119.75785,43.5084112 C119.122336,43.3598131 118.555047,43.2196262 118.056916,43.088785 C117.55785,42.9588785 117.116729,42.7130841 116.729813,42.3523364 C116.343832,41.9915888 116.150374,41.4205607 116.150374,40.6364486 C116.150374,39.5429907 116.567196,38.7037383 117.401776,38.1196262 C118.23729,37.535514 119.227944,37.2429907 120.374673,37.2429907 C120.95972,37.2429907 121.508318,37.2897196 122.019533,37.3841121 C122.530748,37.4766355 123.035421,37.635514 123.533551,37.8598131 L122.393364,40.0093458" id="Fill-28" fill="#000000"></path>
    <path d="M132.243925,40.9265421 C132.157009,40.4760748 131.931776,40.1171963 131.571028,39.8499065 C131.209346,39.5826168 130.792523,39.4499065 130.318692,39.4499065 C129.795327,39.4499065 129.353271,39.573271 128.992523,39.8218692 C128.629907,40.0723364 128.399065,40.4396262 128.3,40.9265421 L132.243925,40.9265421 Z M135.402804,42.8882243 L128.243925,42.8882243 C128.243925,44.3218692 128.954206,45.0368224 130.374766,45.0368224 C130.760748,45.0368224 131.081308,44.9685981 131.337383,44.8321495 C131.592523,44.6947664 131.814019,44.4714019 132.000935,44.1601869 L135.271963,44.1601869 C135.01028,45.2302804 134.424299,46.0471028 133.514953,46.6078505 C132.604673,47.1685981 131.564486,47.448972 130.394393,47.448972 C128.748598,47.448972 127.41215,47.002243 126.384112,46.1059813 C125.356075,45.2106542 124.842056,43.9592523 124.842056,42.3545794 C124.842056,40.7760748 125.330841,39.5293458 126.309346,38.6143925 C127.28785,37.7013084 128.568224,37.2433645 130.150467,37.2433645 C131.796262,37.2433645 133.082243,37.7162617 134.01028,38.6639252 C134.938318,39.6106542 135.402804,40.9013084 135.402804,42.5349533 L135.402804,42.8882243 L135.402804,42.8882243 Z" id="Fill-29" fill="#000000"></path>
</g>
</g>
</g>
</svg>`;

export const createResultPdf = (resultData: ResultData): TCreatedPdf => {
  /* eslint-disable-next-line */
  const pdfFonts = require('./vfs_fonts');
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  pdfMake.fonts = {
    OpenSans: {
      normal: 'OpenSans-Regular.ttf',
      bold: 'OpenSans-Bold.ttf',
      bolditalics: 'OpenSans-SemiBold.ttf',
    },
  };

  return pdfMake.createPdf({
    header: (page: number) => {
      if (page === 1) {
        return {
          columns: [
            {
              svg: logoSvg,
              alignment: 'right',
              margin: [0, 42, 21.5, 0],
              fit: [153.1, 100],
            },
          ],
        };
      }
      return {
        columns: [],
      };
    },
    content: [
      {
        text: GLOBAL_LABELS.headline,
        style: 'h1',
        margin: [0, 100, 0, 0],
      },
      {
        text: GLOBAL_LABELS.subheadline,
        style: 'paragraph',
      },
      {
        canvas: [{
          type: 'line', x1: -100, y1: 50, x2: 700, y2: 50, lineWidth: 3, lineColor: '#FBF315',
        }],
      },
      {
        text: `${GLOBAL_LABELS.date} ${resultData.calculationDate}`,
        style: 'paragraphGrey',
        margin: [0, 20, 0, 15],
      },
      ...formatResultCategories(resultData.categories),
      formatCta(),
      {
        text: GLOBAL_LABELS.disclaimer,
        style: 'paragraph',
        margin: [0, 20, 0, 15],
      },
    ],
    footer: {
      stack: [
        {
          text: GLOBAL_LABELS.footerHeadline,
          style: 'footerHeadline',
        },
        {
          text: GLOBAL_LABELS.footerContent,
          style: 'footerContent',
        },
      ],
      height: 200,
      margin: [40, 30, 40, 0],
    },
    styles: {
      h1: {
        font: 'OpenSans',
        fontSize: 24,
        bold: true,
        italics: true,
      },
      h2: {
        fontSize: 13.5,
        bold: true,
        italics: true,
      },
      paragraph: {
        fontSize: 10.5,
      },
      paragraphGrey: {
        fontSize: 10.5,
        color: '#909090',
      },
      footerHeadline: {
        fontSize: 7,
        bold: true,
        italics: true,
      },
      footerContent: {
        fontSize: 7,
      },
      link: {
        fontSize: 10.5,
        bold: true,
        italics: true,
        alignment: 'center',
      },
      cta: {
        fillColor: '#FBF315',
        alignment: 'center',
      },
    },
    defaultStyle: {
      font: 'OpenSans',
      color: '#373737',
    },
    pageMargins: [40, 70, 40, 120],
  });
};

const formatResultCategories = (resultCategories: ResultCategory[]): Content[] => resultCategories.map((cat) => ([
  {
    layout: 'noBorders',
    table: {
      widths: [200, '*'],
      body: [
        [{
          text: cat.headline.toUpperCase(),
          style: 'h2',
          margin: [0, 0, 0, 15],
        },
        '',
        ],
        ...cat.parameters.map((param) => {
          if (param.bold) {
            return [
              {
                text: `${param.key}:`,
                bold: true,
                style: 'paragraph',
              },
              {
                text: param.value,
                bold: true,
                style: 'paragraph',
              },
            ];
          }
          return [
            {
              text: `${param.key}:`,
              style: 'paragraph',
            },
            {
              text: param.value,
              style: 'paragraph',
            },
          ];
        })],
    },
    margin: [0, 15, 0, 0],
  },
  {
    canvas: [{
      type: 'line', x1: -100, y1: 20, x2: 700, y2: 20, lineWidth: 1, lineColor: '#E4E4E4',
    }],
  },
]));

const formatCta = (): Content => ({
  layout: 'noBorders',
  style: 'cta',
  margin: [167, 20, 0, 0],
  table: {
    body: [
      [
        {
          style: 'link',
          text: GLOBAL_LABELS.cta,
          margin: [15, 10, 15, 10],
          width: 120,
          link: CTA_HREF,
        },
      ],
    ],
  },
});
