import React, { useState, useEffect, useContext } from 'react';
import { Container, Grid } from '@material-ui/core';

import {
  IconLink, Section, HeroImage, Paragraph, Headline, Link, Button, HeroNumber, RangeVisualizer, Separator,
} from '@virtualidentityag/rbi-ui-components';
import { TrackingObjectBuilder } from '../../helpers/tracking/tracking-object-builder';
import { TrackingContext } from '../../contexts/tracking-context';
import { Base64 } from 'js-base64';
import { getUrlSearchParameterFor } from '../../helpers/general/get-url-search-parameter-for';
import { createResultPdf } from '../../helpers/pdf-builder';
import { ReactComponent as DownloadIcon } from '../../assets/download.svg';
import heroImageDesktop from '../../assets/img/hero_welcome_desktop.jpg';
import heroImageMobile from '../../assets/img/hero_welcome_mobile.jpg';
import './styles.scss';
import { separateNumberWithPoints } from '../../helpers/general/separate-numbers-with-points';

import { UserData, ValuationResultData, AddressData } from '../../interfaces/user-data';
import { history } from '../../helpers/general/history';
import { PROPERTY_TYPE_CONFIG, PropertyType } from '../../constants/property-types';
import { convertUserDataToPdfInput } from '../../helpers/pdf-builder/convert-user-data-to-pdf-input';

export const ResultScreen: React.FC = () => {
  const [userData, setUserData] = useState<UserData | undefined>(undefined);
  const [propertyType, setPropertyType] = useState<string | undefined>();
  const [propertyAddress, setPropertyAddress] = useState<AddressData | undefined>();
  const [propertyValue, setPropertyValue] = useState<ValuationResultData>({
    average: 0,
    lowerBorder: 0,
    higherBorder: 0,
    date: '',
  });
  const { pushToDataLayer } = useContext(TrackingContext);

  useEffect(() => {
    const rawUserData = Base64.decode(getUrlSearchParameterFor('valuation-data'));
    if (rawUserData) {
      setUserData(JSON.parse(rawUserData));
    }
  }, []);

  useEffect(() => {
    if (userData) {
      setPropertyValue(userData.valuationResult);
      pushToDataLayer(new TrackingObjectBuilder().forFieldSubmit({
        eventCategory: 'Valuation',
        eventAction: 'Detail',
        eventLabel: `${Math.floor(userData.valuationResult.average)}, ${Math.floor(userData.valuationResult.lowerBorder)} - ${Math.floor(userData.valuationResult.higherBorder)}`,
        eventValue: Math.floor(userData.valuationResult.average),
      }).build());
      if (userData.project.apartmentData) {
        setPropertyAddress(userData.project.apartmentData.address);
        setPropertyType(PROPERTY_TYPE_CONFIG[(userData.project.apartmentData.type as PropertyType)].label);
      }
      if (userData.project.houseData) {
        setPropertyAddress(userData.project.houseData.address);
        setPropertyType(PROPERTY_TYPE_CONFIG[(userData.project.houseData.type as PropertyType)].label);
      }
      if (userData.project.plotData) {
        setPropertyAddress(userData.project.plotData.address);
        setPropertyType(PROPERTY_TYPE_CONFIG[(userData.project.plotData.type as PropertyType)].label);
      }
    }
  }, [userData, pushToDataLayer]);

  const onPdfRequest = () => {
    if (userData) {
      const pdfInput = convertUserDataToPdfInput(userData);
      if (pdfInput) {
        createResultPdf(pdfInput).download(`Ihr ImmoCheck Ergebnis vom ${pdfInput.calculationDate}.pdf`);
      }
    }
  };

  return (
    <React.Fragment>
      <HeroImage
        className='results__hero-image'
        caption='Das Ergebnis ihres ImmoChecks'
        figcaption={`Ihr WohnTraum: ${propertyType}`}
        srcDesktop={heroImageDesktop}
        srcMobile={heroImageMobile}
        inBackground
        smallOnMobile
        showFigcaptionOnMobile
      ></HeroImage>
      <div className='results__container'>
        <Container maxWidth='lg' disableGutters>
          <Grid container justify='center' spacing={3}>
            <Grid item xs={12} md={9}>
              <Section withMargin centeredContent narrow>
                <Headline uppercase level={3}>Schätzwert Ihres WohnTraums {propertyAddress ? `in ${propertyAddress.postCode || ''} ${propertyAddress.city}` : ''}</Headline>
                <HeroNumber>{separateNumberWithPoints(propertyValue.average)} EUR</HeroNumber>
                <RangeVisualizer
                  title='Preisspanne'
                  lowerBorder={`${separateNumberWithPoints(propertyValue.lowerBorder)} EUR`}
                  higherBorder={`${separateNumberWithPoints(propertyValue.higherBorder)} EUR`}
                />
                <Paragraph className='results__info-text'>
                                    Der angegebene Schätzwert basiert auf aktuellen Marktdaten. Abweichungen innerhalb der Preisspanne können jedoch nicht ausgeschlossen werden. Mehr Informationen zur Bewertung finden Sie <Link onLinkClick={() => history.push('/terms-of-use')} inline>hier</Link>.
                </Paragraph>
                <Separator variant='straight'></Separator>
                <Headline uppercase level={3}>Sie möchten wissen, wie Sie Ihren WohnTraum finanzieren können?</Headline>
                <Paragraph className='results__info-text'>
                                Mit dem WohnTraumRechner der Raiffeisen Bausparkasse erhalten Sie die passende Finanzierungslösung für Ihren WohnTraum. Für mehr Sicherheit können Sie sich im Anschluss an Ihre Berechnung direkt eine vorläufige Finanzierungszusage holen.
                </Paragraph>
                <Button onButtonClick={() => {
                  window.location.href = 'https://wohntraumrechner.raiffeisen.at/?utm_source=immocheck-screen-ergebnis&utm_medium=clickout&utm_campaign=immocheck-2020&utm_content=link';
                  pushToDataLayer(new TrackingObjectBuilder().forClick({
                    eventLabel: 'Zum Wohntraumrechner',
                    eventCategory: 'Button',
                  }).build());
                }}>ZUM WOHNTRAUMRECHNER</Button>
              </Section>
              <Section centeredContent transparent narrow>
                <IconLink
                  icon={<DownloadIcon />}
                  onLinkClick={() => {
                    onPdfRequest();
                    pushToDataLayer(new TrackingObjectBuilder().forClick({
                      eventLabel: 'ImmoCheck Ergebnis heruntergeladen',
                      eventCategory: 'Button',
                    }).build());
                  }}
                >ImmoCheck-Ergebnis herunterladen</IconLink>
              </Section>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
};
