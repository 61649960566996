import React, {
  createContext, useState, Dispatch, SetStateAction,
} from 'react';

export type Gender = 'Herr' | 'Frau' | undefined;

interface PersonalContextOptions {
  gender: Gender;
  setGender: Dispatch<SetStateAction<Gender>>;
  firstName: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  lastName: string;
  setLastName: Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
}

export const PersonalContext = createContext<PersonalContextOptions>({
  gender: undefined,
  setGender: () => undefined,
  firstName: '',
  setFirstName: () => '',
  lastName: '',
  setLastName: () => '',
  email: '',
  setEmail: () => '',
});

export const PersonalProvider: React.FC = ({ children }) => {
  const [gender, setGender] = useState<Gender>(undefined);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  return (
    <PersonalContext.Provider value={{
      gender, setGender, firstName, setFirstName, lastName, setLastName, email, setEmail,
    }}>
      {children}
    </PersonalContext.Provider>
  );
};
