import { Header } from '@virtualidentityag/rbi-ui-components';
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Router, Switch } from 'react-router-dom';
import { API } from 'aws-amplify';
import { Footer } from './components/footer/footer';

import awsmobile from './aws-exports';

import { QuestionnaireContext } from './contexts/questionnaire-context';
import { PropertyDetailsScreen } from './screens/property-details-questionnaire';
import { PropertyTypeQuestionnaireScreen } from './screens/property-type-questionnaire';
import { ReviewScreen } from './screens/questionnaire-review';
import { SubmissionScreen } from './screens/submission/index';
import { TermsOfUseScreen } from './screens/terms-of-use/index';
import { WelcomeScreen } from './screens/welcome';

import { history } from './helpers/general/history';
import { RouterScrollToTop } from './helpers/general/router-scroll-to-top';
import { ResultScreen } from './screens/result/index';

export const App: React.FC = () => {
  useEffect(() => {
    const apiKey = process.env.AMPLIFY_API_KEY_MASTER as string;

    API.configure({
      ...awsmobile,
      ...(apiKey && { aws_appsync_apiKey: apiKey }),
    });
  }, []);

  const { stepOptions } = useContext(QuestionnaireContext);

  const progressData = {
    stepLabels: [
      'Adressdaten',
      'Größe und Zustand',
      'Übersicht',
      'Kontaktdaten',
    ],
    routes: [
      '/property-type-questionnaire',
      '/questionnaire-review',
      '/property-details-questionnaire',
      '/submission',
    ],
  };

  return (
    <div className='application'>
      <Router history={history}>
        <Helmet>
          <link rel='canonical' href={window.location.href} />
        </Helmet>
        <Header
          stepOptions={stepOptions}
          progressData={progressData}
          history={history}
          onLogoClick={() => history.push('/')}
        />
        <div className='main'>
          <Switch>
            <Route exact path='/' component={WelcomeScreen} />
            <Route path='/property-type-questionnaire' component={PropertyTypeQuestionnaireScreen} />
            <Route path='/property-details-questionnaire' component={PropertyDetailsScreen} />
            <Route path='/questionnaire-review' component={ReviewScreen} />
            <Route path='/submission' component={SubmissionScreen} />
            <Route path='/result' component={ResultScreen} />
            <Route path='/terms-of-use' component={TermsOfUseScreen} />
          </Switch>
          <RouterScrollToTop />
        </div>
        <Footer />
      </Router>
    </div>
  );
};
