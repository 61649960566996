import {Environment} from '../interfaces/env';

export const DOMAINS = {
  develop: 'https://develop.djt1877dc4uuw.amplifyapp.com',
  master: 'https://immocheck.bausparen.at',
};
export const TRACKING = {
  gtmId: 'GTM-NHJFMLB',
};

export const getDomainForEnvironment = (env: Environment) => DOMAINS[env] || 'https://immocheck.bausparen.at';

export const VALID_API_CALLS_PER_DAY = 3;
export const VALID_API_CALLS_PER_MONTH = 10;
