import React, { useContext, useEffect } from 'react';
import {
  Section,
  Headline,
  Paragraph,
  Button,
  ButtonContainer,
  ReviewBox,
} from '@virtualidentityag/rbi-ui-components';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {
  QuestionnaireContext,
  PropertyExtras,
} from '../../contexts/questionnaire-context';
import { history } from '../../helpers/general/history';
import {
  PROPERTY_TYPE_CONFIG,
  PropertyType,
} from '../../constants/property-types';
import {
  PROPERTY_STATE_LABELS,
  PropertyState,
} from '../../constants/property-states';
import { PLOT_STATE_LABELS, PlotState } from '../../constants/plot-states';

export const ReviewScreen = () => {
  const {
    street,
    houseNumber,
    postCode,
    city,
    propertyType,
    livingArea,
    plotArea,
    buildYear,
    propertyState,
    plotState,
    extras,
  } = useContext(QuestionnaireContext);

  interface PropertyNames {
    hasLoggia: string;
    hasGarden: string;
    hasLift: string;
    hasTerrace: string;
    hasBalcony: string;
    hasCellar: string;
    hasAttic: string;
    hasGarage: string;
  }

  const extrasNames = {
    hasLoggia: 'Loggia',
    hasGarden: 'Eigengarten',
    hasLift: 'Aufzug',
    hasTerrace: 'Terrasse',
    hasBalcony: 'Balkon',
    hasCellar: 'Keller',
    hasAttic: 'Lage im Dachgeschoss',
    hasGarage: 'Garage',
  };

  const trueExtras = (extras: PropertyExtras, names: PropertyNames) => {
    const arr: Array<string> = [];
    Object.entries(extras).forEach((entry) => {
      if (entry[1] === true) {
        Object.entries(names).forEach((name) => {
          if (entry[0] === name[0]) {
            arr.push(` ${name[1]}`);
          }
        });
      }
    });
    return arr.toString();
  };

  const extrasString = trueExtras(extras, extrasNames);
  const { setStepOptions } = useContext(QuestionnaireContext);

  useEffect(() => {
    setStepOptions({
      step: 2,
      hideStepper: false,
    });
  }, [setStepOptions]);

  const propertyData = {
    Straße: `${street}`,
    Hausnummer: `${houseNumber}`,
    Postleitzahl: `${postCode}`,
    Ort: `${city}`,
  };

  const sizeStateData = {
    Wohnfläche: livingArea ? `${livingArea} m²` : '',
    Grundstücksfläche: plotArea ? `${plotArea} m²` : '',
    Zustand:
      propertyState === ''
        ? ''
        : `${PROPERTY_STATE_LABELS[propertyState as PropertyState]}`,
    Widmung:
      plotState === '' ? '' : `${PLOT_STATE_LABELS[plotState as PlotState]}`,
    Baujahr: buildYear ? `${buildYear}` : '',
    Ausstattung: `${extrasString}`,
  };

  return (
    <React.Fragment>
      <Container maxWidth='md' disableGutters>
        <Grid container spacing={3} justify='center'>
          <Grid item xs={12} md={9}>
            <Section transparent>
              <Headline level={2}>Bereit für den ImmoCheck</Headline>
              <Paragraph>
                Bitte bestätigen Sie die Angaben zu Ihrer Wunschimmobilie.
              </Paragraph>
              <ReviewBox
                propertyKind={
                  PROPERTY_TYPE_CONFIG[propertyType as PropertyType]
                }
                onEdit={() => history.push('/property-type-questionnaire')}
                answers={propertyData}
                headline='Immobilie'
              />
              <ReviewBox
                onEdit={() => history.push('/property-details-questionnaire')}
                answers={sizeStateData}
                headline='Größe &amp; Zustand'
              />
              <ButtonContainer>
                <Button onButtonClick={() => history.push('/submission')}>
                  Weiter
                </Button>
                <Button
                  theme='secondary'
                  onButtonClick={() => history.push('/property-details-questionnaire')
                  }
                >
                  zurück
                </Button>
              </ButtonContainer>
            </Section>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};
