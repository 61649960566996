import React from 'react';

import Container from '@material-ui/core/Container';
import {
  Section,
  Button,
  ButtonContainer,
  Paragraph,
  Headline,
  Link,
} from '@virtualidentityag/rbi-ui-components';
import Grid from '@material-ui/core/Grid';
import { history } from '../../helpers/general/history';

export const TermsOfUseScreen: React.FC = () => (
  <Container maxWidth='md' disableGutters>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Section transparent noMarginTop>
          <Headline uppercase level={1} noMargin centered>
              Nutzungsbedingungen
          </Headline>
        </Section>
        <Section>
          <Paragraph>
              Beim ImmoCheck handelt es sich um eine von der Raiffeisen
              Bausparkasse Gesellschaft m.b.H. (dem „Betreiber“) zur Verfügung
              gestellten Plattform (die „Plattform“), über die Nutzer in wenigen
              Schritten den Schätzwert ihrer Wunschimmobilie ermitteln können.
          </Paragraph>
          <Paragraph>
              Für den Zugang und die Nutzung des ImmoCheck gelten folgende
              Bedingungen:
          </Paragraph>
          <Paragraph>
              1. Der Zugriff auf die Plattform und die Nutzung des Service ist
              kostenlos und je Nutzer bzw. IP-Adresse mit 3 Zugriffen pro Tag
              bzw. 10 Zugriffen pro Monat limitiert. Automatisierte Abfragen,
              Spam-Abfragen bzw. systematische Abfragen sowie die Umgehung der oben genannten zahlenmäßigen
              Zugriffsbeschränkung sind unzulässig.
          </Paragraph>
          <Paragraph>
              2. Der Zugang und die Nutzung der Plattform setzen die
              vollständige und zutreffende Angabe der erforderlichen Objekt- und
              Kontaktdaten voraus. Voraussetzung der Nutzung ist weiters, dass
              die gegenständlichen Nutzungsbedingungen akzeptiert und laufend
              beachtet werden.
          </Paragraph>
          <Paragraph>
              3. Die Plattform erlaubt es Nutzern, eine Wertermittlung für in
              Österreich gelegene Immobilien vorzunehmen. Dazu gibt der Nutzer
              die Adresse der zu bewertenden Immobilie, einige wichtige
              Objekteigenschaften wie Grundstücks- und/oder Wohnfläche,
              Stockwerkslage, sonstige Ausstattungsmerkmale (Balkon, Terrasse,
              Loggia, Keller, Garage, Aufzug) sowie Baujahr und Gebäudezustand
              ein und erhält eine E-Mail mit einem Link, über den das
              Bewertungsergebnis abgerufen und in ein Dokument exportiert werden
              kann („Ergebnisdokument“).
          </Paragraph>
          <Paragraph>
              4. Die Wertermittlung erfolgt in Kooperation mit der DataScience
              Service GmbH („DSS“), Neubaugasse 56/2/9, 1070 Wien (FN 451012 d);
              siehe auch Punkt 17 ff.
          </Paragraph>
          <Paragraph>
              5. Der ImmoCheck ist eine reine Online-Wertermittlung auf Basis
              der Angaben des Nutzers in der entsprechenden Suchmaske. Der
              Betreiber hat keine Möglichkeit, diese Angaben zu überprüfen. Es
              wird ausdrücklich darauf hingewiesen, dass die dem Nutzer aufgrund
              seiner Anfrage zur Verfügung gestellten Vergleichsdaten und
              Informationen ausschließlich auf den verfügbaren und für diese
              Zwecke verwendbaren Vergleichsdaten beruhen, eine Besichtigung der
              Objekte oder der Lage und Umgebung derselben durch den Betreiber
              ist nicht erfolgt. Der ausgewiesene Wert kann zudem von weiteren
              Faktoren abhängig sein, die im Rahmen einer Erstanalyse auf der
              Plattform nicht oder nicht vollständig berücksichtigt werden
              können; dies beinhaltet insbesondere, aber nicht ausschließlich,
              Baumängel oder -schäden, ungewöhnliche Ausstattungsmerkmale,
              schlechte Raumkonfigurationen etc. Ebenfalls unberücksichtigt
              bleiben allfällige bücherliche oder außerbücherliche Lasten
              (Pfandrechte, Wohnrechte, Fruchtgenussrechte, Belastungs- und
              Veräußerungsverbote etc.), das (Nicht-) Vorliegen baubehördlicher
              Genehmigungen, Fragen der widmungsgemäßen oder sonst rechtmäßigen
              Nutzung des Objekts. Für das Ergebnisdokument wird grundsätzlich
              von einer ordnungsgemäßen, rechts- und bescheidkonformen
              Errichtung und Nutzung ausgegangen. Die Bewertung erfolgt im
              Vergleichswertverfahren unter Berücksichtigung der Preise für
              vergleichbare Grundstücke und Baulichkeiten sowie der besonderen
              Verhältnisse. Zugrunde gelegt werden die Adressen,
              Ausstattungsmerkmale und Preise wie in den verwendeten
              Datengrundlagen angegeben und mit technischen Verfahren ergänzt.
              Dabei werden grundbücherliche Kaufpreise sowie darüber hinaus auch
              Daten und Preise von Immobilienangeboten verwendet und mit
              technischen Methoden zusammengeführt und plausibilisiert. Mithilfe
              statistischer Verfahren werden Korrekturfaktoren ermittelt, die
              zur Ermittlung der Vergleichswerte angewendet werden. Insbesondere
              wird auch Bedacht genommen auf die Lage und Ausstattung. Der
              Erhaltungszustand des Bewertungsobjektes muss vom Nutzer im Rahmen
              des Lokalaugenscheines festgestellt und klassifiziert werden. Eine
              über die Eingabemöglichkeiten im ImmoCheck hinausgehende
              Wertanpassung, ist nicht möglich. Es wird darauf hingewiesen, dass
              der tatsächlich erzielbare Verkaufswert erfahrungsgemäß innerhalb
              einer Streuung von +/- 15 % des errechneten Wertes liegt, wobei
              diese Streuung vom Nutzer nicht beeinflusst werden kann und unter
              Anderem davon abhängig ist, ob ein zeitnaher Verkauf erforderlich
              ist oder dafür mehr Zeit zur Verfügung steht (auf Punkt 9 wird ausdrücklich verwiesen).
          </Paragraph>
          <Paragraph>
              6. Die angezeigten Daten sowie das Ergebnisdokument dürfen – ohne
              die vorherige Zustimmung des Betreibers – vom Nutzer
              ausschließlich für eigene, interne Informationszwecke verwendet
              und insbesondere nicht an Dritte weitergegeben werden,
              gleichgültig ob eine solche Weitergabe entgeltlich oder
              unentgeltlich erfolgt.
          </Paragraph>
          <Paragraph>
              7. Die Plattform sowie das Ergebnisdokument enthalten Elemente und
              Inhalte, die zugunsten des Betreibers, der DSS oder deren
              Kooperationspartner (etwa nach Urheber- /Leistungsschutzrecht,
              Markenrecht, Patent- /Gebrauchsmusterrecht, Geschmacksmusterrecht,
              Wettbewerbsrecht oder durch sonstige Normen) geschützt sind oder
              geschützt sein können. Der Betreiber, die DSS sowie deren
              Kooperationspartner behalten sich sämtliche Rechte daran
              ausdrücklich vor. Die über die gewöhnliche und erforderliche
              Inanspruchnahme der Plattform durch den Nutzer hinausgehende
              Nutzung dieser Elemente und Inhalte (zur Gänze oder zum Teil),
              sowie insbesondere deren Vervielfältigung oder „reverse
              engineering“, ist ohne vorherige schriftliche Zustimmung des
              Betreibers nicht gestattet. Dies gilt insbesondere, aber nicht
              ausschließlich, für die Software (mit Ausnahme allfälliger
              Open-Source Elemente), statistische Berechnungsmodelle, Know-How,
              Marken, Namen und Kennzeichen, Inhalt, Layout, Gestaltung und
              Interface der Website sowie der Plattform, Datenbanken, Videos,
              Lichtbilder, Texte sowie Grafiken. Dem Nutzer ist insbesondere
              nicht gestattet, die Plattform oder darin enthaltene Elemente für
              die Erstellung einer eigenen Datenbank oder ähnliche Nutzungen zu
              verwenden.
          </Paragraph>
          <Paragraph>
              8. Der Betreiber übernimmt keine Gewähr oder Haftung für (i) eine
              bestimmte Eigenschaft, Eignung oder Tauglichkeit der Plattform,
              (ii) die ununterbrochene oder fehlerfreie Verfügbarkeit der
              Plattform, die Möglichkeit des Zugriffs oder der Interaktion,
              (iii) Übertragungs-, Hardware-, Software- oder Netzwerkfehler,
              (iv) verlorene, beschädigte, unvollständige, veränderte oder
              verspätete Übermittlung von Daten, (v) Angriffe oder Zugriffe
              durch unautorisierte Dritte innerhalb der Plattform oder von
              außen.
          </Paragraph>
          <Paragraph bold>
              9. Der Betreiber übernimmt insbesondere keine Gewähr, Haftung oder
              Verantwortung dafür, dass die im Rahmen des ImmoCheck ermittelten
              und/oder im Ergebnisdokument enthaltenen Daten, Preise und Werte
              vollständig zutreffend sind oder tatsächlich bestimmte Kaufpreise
              auf dem Markt erzielt werden können. Eine Haftung gegenüber dem
              Nutzer oder Dritten im Zusammenhang mit der Verwendung der
              Plattform und/oder dem Ergebnisdokument wird ausdrücklich
              ausgeschlossen.
          </Paragraph>
          <Paragraph>
              10. Der Betreiber behält sich das Recht vor, den Betrieb der
              Plattform jederzeit und ohne Vorankündigung zu unterbrechen oder
              gänzlich einzustellen. Der Nutzer kann aus einer Unterbrechung
              oder Einstellung keinerlei Rechte oder Ansprüche gegenüber dem
              Betreiber geltend machen.
          </Paragraph>
          <Paragraph>
              11. Der Nutzer sichert zu und leistet Gewähr, dass die angegebenen
              Objektdaten und -informationen nicht unter Verletzung von
              geltendem Recht oder vertraglichen Bestimmungen/Beschränkungen
              angefertigt oder zur Verfügung gestellt wurden, vollständig frei
              von Rechten Dritter sind und durch deren
              Übermittlung/Zurverfügungstellung keine wie immer gearteten Rechte
              Dritter verletzt werden. Dies beinhaltet insbesondere, aber nicht
              ausschließlich, Immaterialgüterrechte, Persönlichkeitsrechte,
              Geheimhaltungsinteressen und Sicherungsrechte.
          </Paragraph>
          <Paragraph>
              12. Jeder Verstoß gegen die Nutzungsbedingungen kann den
              Ausschluss des Nutzers von der Plattform nach Ermessen des
              Betreibers zur Folge haben. Ansprüche des betroffenen Nutzers
              gegen den Betreiber infolge einer solchen Maßnahme sind
              ausgeschlossen.
          </Paragraph>
          <Paragraph>
              13. Der Betreiber behält sich darüber hinaus ausdrücklich vor, die
              personenbezogenen Daten von Nutzern, die gegen die
              Nutzungsbedingungen verstoßen, zum Zweck der Rechtsverfolgung an
              die DSS weiterzugeben. Ansprüche des betroffenen Nutzers gegen den
              Betreiber infolge einer solchen Maßnahme sind ausgeschlossen.
          </Paragraph>
          <Paragraph>
              14. Diese Nutzungsbedingungen unterliegen dem Recht der Republik
              Österreich unter Ausschluss der Kollisionsnormen des
              Internationalen Privatrechts. Als ausschließlicher Gerichtsstand
              im Zusammenhang mit allen Rechtsstreitigkeiten, die sich direkt
              oder indirekt mit diesen Nutzungsbedingungen sowie der Plattform
              und deren Nutzung ergeben, wird die Zuständigkeit des
              Handelsgerichts Wien vereinbart. Dies gilt vorbehaltlich
              gesetzlicher Beschränkungen, insbesondere anwendbarer
              Verbraucherschutzbestimmungen.
          </Paragraph>
          <Headline uppercase level={4}>Datenverarbeitung</Headline>
          <Paragraph>
              15. Sofern der Nutzer eine Immobilie über den ImmoCheck bewerten
              lässt, werden die von ihm zu diesem Zweck auf der Plattform
              angegebenen Daten (Name, E-Mail Adresse und Objektdaten) ohne
              weitergehende Einwilligung zur Durchführung der Bewertung und
              Zurverfügungstellung des Bewertungsergebnisses (gem. Art. 6 Abs. 1
              lit. b DSGVO) verarbeitet. Eine über die Erbringung der
              Dienstleistung hinausgehende Speicherung von personenbezogenen
              Daten durch den Betreiber findet nicht statt.
          </Paragraph>
          <Paragraph>
              16. Weitere Informationen zur Datenverarbeitung erhalten Nutzer
              unter{' '}
            <Link
              href='https://www.bausparen.at/de/unternehmen/datenschutz.html'
              target='_blank'
            >
                bausparen.at/datenschutz
            </Link>
          </Paragraph>
          <Paragraph>
              17. Für die Berechnung des Schätzwertes (Vertragserfüllung) wird
              ein von der DataScience Service GmbH („DSS“) betriebenes Tool
              (ImmAzing) verwendet. Zu diesem Zweck werden die vom Nutzer
              bekanntgegebenen Objektdaten (Art der Immobilie,
              Adresse/Adresskoordinaten, Zustand des Gebäudes,
              Grundstücksfläche, Wohnfläche, ggf. Widmung (Grundstück), Baujahr
              und Ausstattungsmerkmale (Terrasse, Lift…)), nicht jedoch Name
              oder E-Mail Adresse des Nutzers, über eine Schnittstelle an die
              DSS, weitergegeben, soweit die Weitergabe zur Bewertung
              erforderlich ist. Rechtsgrundlage für die Weitergabe der Daten ist
              (soweit personenbezogene Daten betroffen sind) Art. 6 Abs. 1 lit.
              b DSGVO. Nähere Informationen zum Datenschutz von DSS als für die
              Datenverarbeitung im Rahmen der Berechnung des Schätzwertes
              Verantwortlicher finden Nutzer unter www.immazing.at/#impressum.
          </Paragraph>
          <Paragraph>
              18. Die vom Nutzer auf der Plattform eingegebenen Objektdaten
              können von der DSS weiterverwendet werden. Der Nutzer räumt mit
              der Übermittlung/Zurverfügungstellung der DSS unentgeltlich das
              nichtexklusive, zeitlich, räumlich und inhaltlich unbeschränkte
              Recht ein, diese Daten und Informationen auf sämtliche gegenwärtig
              und künftig bekannte Nutzungsarten in allen Medien in beliebiger
              Verfahrensart und Anzahl zu verwenden. Dies beinhaltet
              insbesondere, aber nicht ausschließlich, das Recht, diese Daten
              und Informationen (und/oder Teile davon) zu vervielfältigen, zu
              speichern, zu verbreiten, zu vermieten/verleihen, darzustellen, zu
              senden, öffentlich wiederzugeben und aufzuführen, der
              Öffentlichkeit (drahtlos oder drahtgebunden) zur Verfügung zu
              stellen sowie mit Werken gleicher oder anderer Art zu verbinden
              (synchronisieren), allumfassend zu bearbeiten, anzupassen und zu
              kürzen, zu teilen und zu verändern und diese Bearbeitungen auf die
              vorgenannten Arten zu verwenden. Es umfasst insbesondere auch die
              Verwendung zur Verbesserung der Plattform oder der
              zugrundeliegenden statistischen Modelle.
          </Paragraph>
          <ButtonContainer>
            <Button theme='secondary' onButtonClick={() => history.goBack()}>
                Zurück
            </Button>
          </ButtonContainer>
        </Section>
      </Grid>
    </Grid>
  </Container>
);
