import { ZustandArt } from '../interfaces/immazing-api';

export type PropertyState = 'not_refurbished' | 'modernized' | 'partially_refurbished' | 'fully_refurbished';

export const PROPERTY_STATE_LABELS: {[key in PropertyState]: string} = {
  not_refurbished: 'Nicht saniert',
  modernized: 'Modernisiert',
  partially_refurbished: 'Teilsaniert',
  fully_refurbished: 'Vollsaniert / Neu',
};

export const PROPERTY_STATE_ZUSTAND_ART_MAP: {[key in PropertyState]: ZustandArt} = {
  not_refurbished: 'nicht_saniert',
  modernized: 'MODERNISIERT',
  partially_refurbished: 'TEIL_SANIERT',
  fully_refurbished: 'VOLL_SANIERT',
};
