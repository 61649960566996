import { PropertyType } from './property-types';

export type QuestionnaireConfig = {
  [checkbox in PropertyType]: {
    checkboxes: Array<string>;
    inputfields: Array<string>;
  };
}

export interface CheckboxConfig {
  [checkbox: string]: {
    state: boolean;
    setState: React.Dispatch<React.SetStateAction<boolean>>;
    name: string;
  };
}

export const QUESTIONNAIRE_CONFIG: QuestionnaireConfig = {
  single_house: {
    checkboxes: ['loggia', 'terrace', 'balcony', 'garage', 'cellar'],
    inputfields: ['Wohnfläche', 'Grundstücksfläche', 'Baujahr', 'Zustand'],
  },
  terraced_house: {
    checkboxes: ['loggia', 'terrace', 'balcony', 'garage', 'cellar'],
    inputfields: ['Wohnfläche', 'Grundstücksfläche', 'Baujahr', 'Zustand'],
  },
  semi_detached_house: {
    checkboxes: ['loggia', 'terrace', 'balcony', 'garage', 'cellar'],
    inputfields: ['Wohnfläche', 'Grundstücksfläche', 'Baujahr', 'Zustand'],
  },
  plot: {
    checkboxes: [],
    inputfields: ['Grundstücksfläche', 'Widmung'],
  },
  apartment: {
    checkboxes: ['loggia', 'terrace', 'balcony', 'cellar', 'lift', 'attic', 'garden'],
    inputfields: ['Wohnfläche', 'Etage', 'Baujahr', 'Zustand'],
  },
};
