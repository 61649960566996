import React, {
  useState, useContext, useEffect, useRef,
} from 'react';
import { Container, Grid } from '@material-ui/core';
import './styles.scss';
import {
  Paragraph,
  Headline,
  Button,
  Section,
  ButtonContainer,
  FormSection,
  InlineError,
  Checkbox,
  CheckboxContainer,
  Dropdown,
  Input,
} from '@virtualidentityag/rbi-ui-components';
import { useForm } from 'react-hook-form';
import { TrackingContext } from '../../contexts/tracking-context';
import { TrackingObjectBuilder } from '../../helpers/tracking/tracking-object-builder';
import {
  QuestionnaireContext,
  PropertyExtras,
} from '../../contexts/questionnaire-context';
import { history } from '../../helpers/general/history';
import {
  QuestionnaireConfig,
  QUESTIONNAIRE_CONFIG,
  CheckboxConfig,
} from '../../constants/questionnaire-config';
import { calculateRange } from '../../helpers/tracking/calculate-range';
import {
  PROPERTY_STATE_LABELS,
  PropertyState,
} from '../../constants/property-states';
import { PLOT_STATE_LABELS, PlotState } from '../../constants/plot-states';

export const PropertyDetailsScreen: React.FC = () => {
  const { propertyType } = useContext(QuestionnaireContext);
  const { livingArea, setLivingArea } = useContext(QuestionnaireContext);
  const { plotArea, setPlotArea } = useContext(QuestionnaireContext);
  const { buildYear, setBuildYear } = useContext(QuestionnaireContext);
  const { floor, setFloor } = useContext(QuestionnaireContext);
  const { propertyState, setPropertyState } = useContext(QuestionnaireContext);
  const { plotState, setPlotState } = useContext(QuestionnaireContext);
  const { extras, setExtras } = useContext(QuestionnaireContext);

  const formRef = useRef(document.createElement('form'));
  const [loggia, setLoggia] = useState<boolean>(
    extras.hasLoggia ? extras.hasLoggia : false,
  );
  const [terrace, setTerrace] = useState<boolean>(
    extras.hasTerrace ? extras.hasTerrace : false,
  );
  const [lift, setLift] = useState<boolean>(
    extras.hasLift ? extras.hasLift : false,
  );
  const [balcony, setBalcony] = useState<boolean>(
    extras.hasBalcony ? extras.hasBalcony : false,
  );
  const [garden, setGarden] = useState<boolean>(
    extras.hasGarden ? extras.hasGarden : false,
  );
  const [cellar, setCellar] = useState<boolean>(
    extras.hasCellar ? extras.hasCellar : false,
  );
  const [attic, setAttic] = useState<boolean>(
    extras.hasAttic ? extras.hasAttic : false,
  );
  const [garage, setGarage] = useState<boolean>(
    extras.hasGarage ? extras.hasGarage : false,
  );
  const [_propertyState, _setPropertyState] = useState<string>(
    propertyState || '',
  );
  const [_plotState, _setPlotState] = useState<string>(
    plotState || '',
  );
  const { pushToDataLayer } = useContext(TrackingContext);

  const validationBuildYear = 'Bitte geben Sie eine Jahreszahl im Format JJJJ ab 1800 an.';
  const validationPlotError = 'Bitte geben Sie eine Grundstücksfläche von maximal 3000 m² als ganze Zahl an.';
  const validationLivingError = 'Bitte geben Sie eine Wohnfläche von maximal 500 m² als ganze Zahl an.';
  const validationFloorError = 'Bitte geben Sie eine Etage von -3 bis 50 an.';
  const validationPropertyStateError = 'Bitte geben Sie den Zustand des Gebäudes an.';

  const { handleSubmit, register, errors } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      livingArea,
      plotArea,
      buildYear,
      floor,
      propertyState,
      extras,
      plotState,
    },
  });

  const { setStepOptions } = useContext(QuestionnaireContext);
  useEffect(() => {
    setStepOptions({
      step: 1,
      hideStepper: false,
    });
  }, [setStepOptions]);

  const onFormSubmit = (formData: Record<string, any>) => {
    setBuildYear(formData.buildYear);
    pushToDataLayer(new TrackingObjectBuilder().forFieldSubmit({
      eventCategory: 'ProjectDetails',
      eventAction: 'Year of Construction',
      eventLabel: `${formData.buildYear}`,
      eventValue: 0,
    }).build());
    setLivingArea(formData.livingArea);
    if (formData.livingArea) {
      pushToDataLayer(new TrackingObjectBuilder().forFieldSubmit({
        eventCategory: 'ProjectDetails',
        eventAction: 'm2',
        eventLabel: calculateRange(formData.livingArea, {
          min: 50,
          max: 250,
          step: 10,
        }),
        eventValue: parseInt(formData.livingArea, 10),
      }).build());
    }
    setPlotArea(formData.plotArea);
    if (formData.plotArea) {
      pushToDataLayer(new TrackingObjectBuilder().forFieldSubmit({
        eventAction: 'Land Area',
        eventCategory: 'ProjectDetails',
        eventLabel: formData.plotArea,
        eventValue: parseInt(formData.plotArea, 10),
      }).build());
    }

    setFloor(formData.floor);
    setPropertyState(_propertyState);
    setPlotState(_plotState);
    pushToDataLayer(new TrackingObjectBuilder().forFieldSubmit({
      eventCategory: 'ProjectDetails',
      eventAction: 'Condition',
      eventLabel: `${
        PROPERTY_STATE_LABELS[_propertyState as PropertyState]
        || PLOT_STATE_LABELS[_plotState as PlotState]
      }`,
      eventValue: 0,
    }).build());

    const extrasCollection: PropertyExtras = {
      hasLoggia: loggia,
      hasGarden: garden,
      hasLift: lift,
      hasTerrace: terrace,
      hasBalcony: balcony,
      hasCellar: cellar,
      hasAttic: attic,
      hasGarage: garage,
    };
    Object.keys(extrasCollection).forEach((key) => {
      if (extrasCollection[key as keyof PropertyExtras]) {
        pushToDataLayer(new TrackingObjectBuilder().forFieldSubmit({
          eventCategory: 'ProjectDetails',
          eventAction: 'Equipment',
          eventLabel: key,
          eventValue: 0,
        }).build());
      }
    });
    setExtras(extrasCollection);
    history.push('/questionnaire-review');
  };

  const CHECKBOXES: CheckboxConfig = {
    loggia: {
      state: loggia,
      setState: setLoggia,
      name: 'Loggia',
    },
    terrace: {
      state: terrace,
      setState: setTerrace,
      name: 'Terrasse',
    },
    lift: {
      state: lift,
      setState: setLift,
      name: 'Aufzug',
    },
    garden: {
      state: garden,
      setState: setGarden,
      name: 'Eigengarten',
    },
    garage: {
      state: garage,
      setState: setGarage,
      name: 'Garage',
    },
    balcony: {
      state: balcony,
      setState: setBalcony,
      name: 'Balkon',
    },
    cellar: {
      state: cellar,
      setState: setCellar,
      name: 'Keller',
    },
    attic: {
      state: attic,
      setState: setAttic,
      name: 'Lage im Dachgeschoss',
    },
  };

  const renderCheckboxes = (
    config: QuestionnaireConfig,
    checkboxes: CheckboxConfig,
    object: string,
  ) => {
    const filteredCheckboxes = Object.entries(config).filter((propertyType) => propertyType[0] === object);
    if (filteredCheckboxes[0]) {
      return filteredCheckboxes[0][1].checkboxes.map((checkbox: string) => {
        const { name } = checkboxes[checkbox];
        const { state } = checkboxes[checkbox];
        const { setState } = checkboxes[checkbox];
        return (
          <Checkbox
            key={name}
            checked={state}
            onCheck={(value: boolean) => setState(value)}
            inputId={name}
            className='checkbox--flex-ie'
          >
            <Paragraph>{name}</Paragraph>
          </Checkbox>
        );
      });
    }
    return [];
  };

  const renderInputFields = (
    config: QuestionnaireConfig,
    input: string,
    object: string,
  ) => {
    let field = '';

    Object.entries(config).forEach((propertyType) => {
      if (propertyType[0] === object) {
        propertyType[1].inputfields.forEach((inputfield: string) => {
          if (inputfield === input) {
            field = inputfield;
          }
        });
      }
    });
    return field;
  };

  return (
    <Container maxWidth='md' disableGutters>
      <Grid container spacing={3} justify='center'>
        <Grid item xs={12} md={9}>
          <form
            ref={formRef}
            onSubmit={handleSubmit((data) => onFormSubmit(data))}
          >
            <Section transparent>
              <Headline level={2}>Wie groß ist Ihr WohnTraum?</Headline>
              <Paragraph withoutMarginTop>
                Machen Sie bitte möglichst genaue Angaben zu Ihrer
                Wunschimmobilie.
              </Paragraph>
              <FormSection>
                {renderInputFields(
                  QUESTIONNAIRE_CONFIG,
                  'Wohnfläche',
                  propertyType,
                ) ? (
                    <Input
                      label='Wohnfläche'
                      unit={
                        <span>
                        m<sup>2</sup>
                        </span>
                      }
                      id='livingArea'
                      autocomplete='Wohnfläche'
                      type='text'
                      validation={register({
                        required: validationLivingError,
                        pattern: /^[1-9]$|^[1-9][0-9]$|^[1-4][0-9][0-9]$|^500$/,
                      })}
                      invalid={!!errors.livingArea}
                      initialFocus={true}
                      initialValue={`${livingArea}`}
                    ></Input>
                  ) : (
                    ''
                  )}
                {errors.livingArea ? (
                  <InlineError>{validationLivingError}</InlineError>
                ) : (
                  ''
                )}
                {renderInputFields(
                  QUESTIONNAIRE_CONFIG,
                  'Etage',
                  propertyType,
                ) ? (
                    <Input
                      label='Etage'
                      id='floor'
                      autocomplete='Etage'
                      type='text'
                      validation={register({
                        pattern: /^$|^-[1-3]$|^[0-9]$|^[1-4][0-9]$|^50$/,
                      })}
                      invalid={!!errors.floor}
                      initialValue={`${floor}`}
                    ></Input>
                  ) : (
                    ''
                  )}
                {errors.floor ? (
                  <InlineError>{validationFloorError}</InlineError>
                ) : (
                  ''
                )}
                {renderInputFields(
                  QUESTIONNAIRE_CONFIG,
                  'Grundstücksfläche',
                  propertyType,
                ) ? (
                    <Input
                      label='Grundstücksfläche'
                      unit={
                        <span>
                        m<sup>2</sup>
                        </span>
                      }
                      id='plotArea'
                      autocomplete='Grundstücksfläche'
                      type='text'
                      validation={register({
                        required: validationPlotError,
                        pattern: /^[1-9]$|^[1-9][0-9]$|^[1-9][0-9][0-9]$|^[1-2][0-9][0-9][0-9]$|^3000$/,
                      })}
                      invalid={!!errors.plotArea}
                      initialValue={`${plotArea}`}
                      initialFocus={propertyType === 'plot'}
                    ></Input>
                  ) : (
                    ''
                  )}
                {errors.plotArea ? (
                  <InlineError>{validationPlotError}</InlineError>
                ) : (
                  ''
                )}
                {renderInputFields(
                  QUESTIONNAIRE_CONFIG,
                  'Zustand',
                  propertyType,
                ) ? (
                    <Dropdown
                      defaultValue={_propertyState}
                      headline='Zustand des Gebäudes'
                      validation={register({
                        required: true,
                      })}
                      description={
                        <p>
                        Um den Zustand des Gebäudes richtig einzuschätzen,
                        beachten Sie bitte die folgenden Informationen: <br />
                          <br />
                        Bei einer <b>Vollsanierung</b> wird das ganze Gebäude
                        saniert und erneuert. Das inkludiert (1) die Erneuerung
                        des Außenverputzes mit Erhöhung des Wärmeschutzes, (2)
                        den Einbau/Austausch von Heizungsanlagen und (3)
                        Installationen, (4) Einbau/Austausch von Badezimmern und
                        (5) den Austausch von Fenstern. <br />
                          <br /> Eine <b>Teilsanierung</b> liegt vor, wenn
                        mindestens 2 der vorher genannten Maßnahmen umgesetzt
                        worden sind. <br />
                          <br />
                        Eine <b>Modernisierung</b> liegt vor, wenn die
                        Bausubstanz zwar unberührt bleibt, aber kleinere
                        Arbeiten und Verschönerungen durchgeführt wurden (bsp.
                        neue Böden und Wandbemalung).
                        </p>
                      }
                      handleChange={(event) => {
                        _setPropertyState(event.target.value);
                      }}
                      name='propertyState'
                      invalid={!!errors.propertyState}
                      options={Object.keys(PROPERTY_STATE_LABELS).map((key) => ({
                        label: PROPERTY_STATE_LABELS[key as PropertyState],
                        value: key,
                      }))}
                    />
                  ) : (
                    ''
                  )}
                {errors.propertyState ? (
                  <InlineError>{validationPropertyStateError}</InlineError>
                ) : (
                  ''
                )}
                {renderInputFields(
                  QUESTIONNAIRE_CONFIG,
                  'Widmung',
                  propertyType,
                ) ? (
                    <Dropdown
                      validation={register({
                        required: true,
                      })}
                      invalid={!!errors.plotState}
                      defaultValue={_plotState}
                      headline='Widmung'
                      handleChange={(event) => {
                        _setPlotState(event.target.value);
                      }}
                      name='plotState'
                      options={Object.keys(PLOT_STATE_LABELS).map((key) => ({
                        label: PLOT_STATE_LABELS[key as PlotState],
                        value: key,
                      }))}
                    />
                  ) : (
                    ''
                  )}
                {renderInputFields(
                  QUESTIONNAIRE_CONFIG,
                  'Baujahr',
                  propertyType,
                ) ? (
                    <Input
                      label='Baujahr'
                      id='buildYear'
                      autocomplete='Baujahr'
                      type='text'
                      validation={register({
                        required: validationBuildYear,
                        maxLength: 4,
                        minLength: 4,
                        pattern: /^18[0-9][0-9]|19\d{2,}|[2-9]\d{3,}$/,
                      })}
                      invalid={!!errors.buildYear}
                      initialValue={`${buildYear}`}
                    ></Input>
                  ) : (
                    ''
                  )}
                {errors.buildYear ? (
                  <InlineError>{validationBuildYear}</InlineError>
                ) : (
                  ''
                )}
                <CheckboxContainer
                  headline={
                    renderCheckboxes(
                      QUESTIONNAIRE_CONFIG,
                      CHECKBOXES,
                      propertyType,
                    )?.length === 0
                      ? ''
                      : 'Ausstattung'
                  }
                >
                  {renderCheckboxes(
                    QUESTIONNAIRE_CONFIG,
                    CHECKBOXES,
                    propertyType,
                  )}
                </CheckboxContainer>
              </FormSection>
              <ButtonContainer>
                <Button type='submit'>weiter</Button>
                <Button
                  theme='secondary'
                  onButtonClick={() => history.push('/property-type-questionnaire')
                  }
                >
                  zurück
                </Button>
              </ButtonContainer>
            </Section>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};
