import {
  Button,
  ButtonContainer,
  Checkbox,
  FormSection,
  Headline,
  InlineError,
  Input,
  Link,
  LoadingSpinner,
  Paragraph,
  Popup,
  Switch,
} from '@virtualidentityag/rbi-ui-components';
import React, {useContext, useEffect, useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {useForm} from 'react-hook-form';
import {Gender, PersonalContext} from '../../contexts/personal-context';
import {QuestionnaireContext} from '../../contexts/questionnaire-context';
import {TrackingContext} from '../../contexts/tracking-context';
import {history} from '../../helpers/general/history';
import {TrackingObjectBuilder} from '../../helpers/tracking/tracking-object-builder';
import {ClassNameProps} from '../../interfaces/classname-props';
import './styles.scss';
import {submitData} from './submit-data';

interface ChildComponentProps extends ClassNameProps {
  onLimitExceeded(): void;
  onSubmitted(emailAddress: string): void;
}

export interface SubmissionFormData {
  gender: string;
  firstName: string;
  lastName: string;
  email: string;
  recaptcha: boolean;
  termsOfUseConsent: boolean;
  contactConsent: boolean;
}

export const SubmissionForm: React.FC<ChildComponentProps> = ({ onLimitExceeded, onSubmitted, className }) => {
  const [isSubmissionInProgress, setIsSubmissionInProgress] = useState<boolean>(false);
  const [submissionError, setSubmissionError] = useState<string>('');

  const questionnaireData = useContext(QuestionnaireContext);

  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    gender,
    setGender,
  } = useContext(PersonalContext);

  const {
    handleSubmit, register, errors, setValue, watch, triggerValidation, formState,
  } = useForm<SubmissionFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      gender,
      firstName,
      lastName,
      email,
      termsOfUseConsent: false,
    },
  });

  useEffect(() => {
    register({ name: 'recaptcha' }, { required: recaptchaError });
    register({ name: 'gender' }, { required: genderError });
    register({ name: 'termsOfUseConsent' }, { required: termsOfUseConsentError });
  }, [register]);

  const _gender = watch('gender');
  const termsOfUseConsent = watch('termsOfUseConsent');
  const { pushToDataLayer } = useContext(TrackingContext);

  const onFormSubmit = async (submissionFormData: SubmissionFormData) => {
    try {
      setIsSubmissionInProgress(true);
      await submitData({questionnaireData, submissionFormData, onSubmitted, onLimitExceeded, pushToDataLayer});
    } catch (e) {
      pushToDataLayer(new TrackingObjectBuilder().forFieldSubmit({
        eventCategory: 'Valuation',
        eventAction: 'Error',
        eventLabel: 'General Submission Error',
        eventValue: 0,
      }).build());
      setIsSubmissionInProgress(false);
      setSubmissionError('Ihre Anfrage kann aktuell leider nicht verarbeitet werden. Bitte versuchen Sie es erneut.');
    }
  };

  const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emailError = 'Bitte überprüfen Sie Ihre E-Mail-Adresse.';
  const genderError = 'Bitte wählen Sie ihr Geschlecht.';
  const nameError = 'Bitte tragen Sie Ihren Vor- und Nachnamen ein.';
  const recaptchaError = 'Bitte bestätigen Sie das reCAPTCHA.';
  const termsOfUseConsentError = 'Bitte stimmen Sie den Nutzungsbedingungen zu.';

  return (
    <React.Fragment>
      <form className={`submission-form ${className || ''}`} onSubmit={handleSubmit((data) => onFormSubmit(data))}>
        <FormSection>
          <Switch value={gender || _gender} onChange={(value) => {
            setValue('gender', value);
            setGender(value as Gender);
            if (formState.submitCount > 0) {
              triggerValidation();
            }
          }} optionOne='Frau' optionTwo='Herr' invalid={!!errors.gender}></Switch>
          {errors.gender ? <InlineError>{(errors.gender as any).message}</InlineError> : ''}
          <Input
            label='Vorname *'
            id='firstName'
            autocomplete='given-name'
            type='text'
            validation={register({
              required: nameError,
            })}
            onInputChange={(value) => {
              setFirstName(value);
            }}
            invalid={!!errors.firstName}
            initialFocus={true}
          ></Input>
          <Input
            label='Nachname *'
            id='lastName'
            autocomplete='family-name'
            type='text'
            validation={register({
              required: nameError,
            })}
            onInputChange={(value) => {
              setLastName(value);
            }}
            invalid={!!errors.lastName}
          ></Input>
          {errors.firstName || errors.lastName ? <InlineError>{nameError}</InlineError> : ''}
          <Input
            label='E-Mail-Adresse *'
            id='email'
            autocomplete='email'
            type='text'
            validation={register(
              {
                pattern: {
                  value: emailPattern,
                  message: emailError,
                },
                required: emailError,
              },
            )}
            onInputChange={(value) => {
              setEmail(value);
            }}
            invalid={!!errors.email}
          ></Input>
          {errors.email ? <InlineError>{(errors.email as any).message}</InlineError> : ''}
        </FormSection>
        <Checkbox checked={termsOfUseConsent} onCheck={() => {
          setValue('termsOfUseConsent', !termsOfUseConsent);
          if (formState.submitCount > 0) {
            triggerValidation();
          }
        }}>
          <Paragraph>
                        Ich stimme den für die Verwendung des ImmoChecks geltenden <Link inline onLinkClick={() => history.push('/terms-of-use')}>Nutzungs­bedingungen</Link> zu.*
          </Paragraph>
        </Checkbox>
        {errors.termsOfUseConsent ? <InlineError>{termsOfUseConsentError}</InlineError> : ''}
        <Paragraph centered grey>Felder mit * sind Pflichtfelder</Paragraph>
        <div className='submission-form__recaptcha'>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
            onChange={(result) => {
              if (result) {
                setValue('recaptcha', true);
                if (formState.submitCount > 0) {
                  triggerValidation();
                }
              }
            }}
          ></ReCAPTCHA>
        </div>
        {errors.recaptcha ? <InlineError>{(errors.recaptcha as any).message}</InlineError> : ''}
        {submissionError ? <InlineError>{submissionError}</InlineError> : ''}
        <ButtonContainer>
          <Button type='submit'>Wert ermitteln</Button>
          <Button theme='secondary' onButtonClick={() => history.push('/questionnaire-review')}>Zurück</Button>
        </ButtonContainer>
      </form>
      <Popup hideCloseIcon addPadding open={isSubmissionInProgress} onPopupClose={() => { }}>
        <LoadingSpinner className='submission-form__progress' />
        <Headline level={3}>Einen Moment bitte, wir ermitteln den Wert der Immobilie...</Headline>
      </Popup>
    </React.Fragment>
  );
};
