import React, { useState, useEffect, useContext } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {
  Section, Headline, Paragraph, NotificationBanner, Button, ButtonContainer, WarningBox, Link,
} from '@virtualidentityag/rbi-ui-components';
import { TrackingContext } from '../../contexts/tracking-context';
import { TrackingObjectBuilder } from '../../helpers/tracking/tracking-object-builder';
import { ReactComponent as CheckmarkIcon } from '../../assets/checkmark.svg';
import { ReactComponent as WarningIcon } from '../../assets/warning.svg';
import { SubmissionForm } from '../../components/submission-form/index';
import { scrollToTop } from '../../helpers/general/scroll-to-top';
import { QuestionnaireContext } from '../../contexts/questionnaire-context';

export const SubmissionScreen: React.FC = () => {
  const [view, setView] = useState<'submission' | 'confirmation' | 'limit-exceeded'>('submission');
  const [userEmailAddress, setUserEmailAddress] = useState<string>('');
  const { setStepOptions } = useContext(QuestionnaireContext);
  const { pushToDataLayer } = useContext(TrackingContext);

  useEffect(() => {
    setStepOptions({
      step: 3,
      hideStepper: view !== 'submission',
    });
  }, [view, setStepOptions]);

  return (
    <Container maxWidth='md' disableGutters>
      <Grid container justify='center' spacing={3}>
        <Grid item xs={12} md={9}>
          {view === 'submission' ? (
            <Section transparent>
              <Headline noMargin level={2}>Fast fertig: Sie müssen nur noch Ihre Kontaktdaten angeben.</Headline>
              <Paragraph>Die Daten zu Ihrer Traumimmobilie sind nun komplett. Jetzt fehlen nur noch Ihre Kontaktdaten, damit wir Ihnen das Ergebnis Ihres ImmoChecks per E-Mail zukommen lassen können.</Paragraph>
              <Paragraph>Nähere Informationen zum Datenschutz erhalten Sie <Link href='https://www.bausparen.at/de/unternehmen/datenschutz.html'>hier</Link>.</Paragraph>
              <SubmissionForm onSubmitted={(emailAddress) => {
                scrollToTop();
                setUserEmailAddress(emailAddress);
                setView('confirmation');
              }} onLimitExceeded={() => {
                scrollToTop();
                setView('limit-exceeded');
              }}></SubmissionForm>
            </Section>
          ) : ''}
          {view === 'confirmation' ? (
            <React.Fragment>
              <NotificationBanner icon={<CheckmarkIcon></CheckmarkIcon>}>
                <Headline level={2}>Ihr Bewertungsergebnis ist auf dem Weg</Headline>
                <Paragraph>Wir haben die von Ihnen angegebenen Objektdaten ausgewertet. In wenigen Sekunden erhalten Sie eine E-Mail an <b>{userEmailAddress}</b> mit einem Link zum errechneten Schätzwert.</Paragraph>
              </NotificationBanner>
              <Section transparent noMarginTop noVerticalPadding>
                <WarningBox>
                                Bitte beachten Sie, dass die Anzahl der Immobilien-Bewertungen durch den ImmoCheck limitiert sind. Sie haben die Möglichkeit, 3 Bewertungen pro Tag bzw. 10 Bewertungen pro Monat durchzuführen.
                </WarningBox>
              </Section>
              <Section transparent noMarginTop noVerticalPadding>
                <Headline level={4} centered>Entscheidungen trifft man am besten mit einem guten Gefühl!</Headline>
                <Paragraph centered>Mit dem WohnTraumRechner der Raiffeisen Bausparkasse erhalten Sie die passende Finanzierungslösung für Ihren WohnTraum - auf Wunsch sogar mit einer vorläufigen Finanzierungszusage!</Paragraph>
                <ButtonContainer>
                  <Button onButtonClick={() => {
                    window.location.href = 'https://wohntraumrechner.raiffeisen.at/?utm_source=immocheck-screen-bestätigung&utm_medium=clickout&utm_campaign=immocheck-2020&utm_content=link';
                    pushToDataLayer(new TrackingObjectBuilder().forClick({
                      eventLabel: 'Zum Wohntraumrechner',
                      eventCategory: 'Button',
                    }).build());
                  }}>
                                        Zum Wohntraumrechner
                  </Button>
                </ButtonContainer>
              </Section>
            </React.Fragment>
          ) : ''}
          {view === 'limit-exceeded' ? (
            <React.Fragment>
              <NotificationBanner icon={<WarningIcon></WarningIcon>}>
                <Headline level={2}>Sie haben das Limit überschritten!</Headline>
                <Paragraph>Bitte beachten Sie, dass die Anzahl der Immobilien-Bewertungen pro Nutzer durch den ImmoCheck limitiert sind. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.</Paragraph>
              </NotificationBanner>
              <Section transparent noMarginTop>
                <Paragraph centered>Bis dahin können Sie gerne die anderen Services der Raiffeisen Bausparkasse nutzen. Mit dem WohnTraumRechner erhalten Sie die passende Finanzierungslösung für Ihren WohnTraum - auf Wunsch mit einer vorläufigen Finanzierungszusage. Denn große Entscheidungen, trifft man am besten mit einem guten Gefühl!</Paragraph>
                <ButtonContainer>
                  <Button onButtonClick={
                    () => {
                      window.location.href = 'https://wohntraumrechner.raiffeisen.at/?utm_source=immocheck-screen-limit&utm_medium=clickout&utm_campaign=immocheck-2020&utm_content=link';
                      pushToDataLayer(new TrackingObjectBuilder().forClick({
                        eventLabel: 'Zum Wohntraumrechner',
                        eventCategory: 'Button',
                      }).build());
                    }}>
                                        Zum Wohntraumrechner
                  </Button>
                </ButtonContainer>
              </Section>
            </React.Fragment>
          ) : ''}
        </Grid>
      </Grid>
    </Container>
  );
};
