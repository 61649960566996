/* eslint-disable consistent-return */

import { API, graphqlOperation } from 'aws-amplify';
import { sendCalculationResultEmail } from '../../graphql/queries';

interface CalculationEmailInput {
  email: string;
  lastName: string;
  gender: string;
  redirectUrl: string;
}

export const requestCalculationEmail = async ({
  email, lastName, gender, redirectUrl,
}: CalculationEmailInput): Promise<void | Error> => {
  const sendEmailResult: any = await API.graphql(graphqlOperation(sendCalculationResultEmail, {
    userData: JSON.stringify({
      email,
      lastName,
      gender,
      redirectUrl,
    }),
  }));
  if (JSON.parse(sendEmailResult.data.sendCalculationResultEmail).err) {
    return new Error();
  }
};
