import { UserData } from '../../interfaces/user-data';
import { ResultData } from './index';
import { PROPERTY_STATE_LABELS } from '../../constants/property-states';
import { PROPERTY_TYPE_CONFIG } from '../../constants/property-types';
import { separateNumberWithPoints } from '../general/separate-numbers-with-points';
import { PLOT_STATE_LABELS } from '../../constants/plot-states';

const EXISTING_LABEL = 'Vorhanden';
const NOT_EXISTING_LABEL = 'Nicht Vorhanden';
const YES_LABEL = 'Ja';
const NO_LABEL = 'Nein';

export const convertUserDataToPdfInput = (userData: UserData): ResultData | undefined => {
  const valuation = userData.valuationResult;
  if (userData.project.apartmentData) {
    const apartment = userData.project.apartmentData;

    return {
      calculationDate: userData.valuationResult.date,
      categories: [
        {
          headline: 'Adresse',
          parameters: [{
            key: 'Adresse',
            value: `${apartment.address.street} ${apartment.address.houseNumber}, ${apartment.address.postCode} ${apartment.address.city}`,
          }],
        },
        {
          headline: 'Immobilienbewertung',
          parameters: [{
            key: 'Obergrenze',
            value: `${separateNumberWithPoints(valuation.higherBorder)} EUR`,
          }, {
            key: 'Schätzwert',
            value: `${separateNumberWithPoints(valuation.average)} EUR`,
            bold: true,
          }, {
            key: 'Untergrenze',
            value: `${separateNumberWithPoints(valuation.lowerBorder)} EUR`,
          }],
        },
        {
          headline: 'Objektdaten',
          parameters: [{
            key: 'Immobilientyp',
            value: `${PROPERTY_TYPE_CONFIG[apartment.type].label}`,
          }, {
            key: 'Baujahr',
            value: `${apartment.buildYear}`,
          }, {
            key: 'Wohnfläche',
            value: `${apartment.livingArea} m²`,
          }, {
            key: 'Bauzustand',
            value: `${PROPERTY_STATE_LABELS[apartment.propertyState]}`,
          }, {
            key: 'Etage',
            value: `${apartment.floor}`,
          }, {
            key: 'Loggia',
            value: `${apartment.hasLoggia ? EXISTING_LABEL : NOT_EXISTING_LABEL}`,
          }, {
            key: 'Balkon',
            value: `${apartment.hasBalcony ? EXISTING_LABEL : NOT_EXISTING_LABEL}`,
          }, {
            key: 'Terrasse',
            value: `${apartment.hasTerrace ? EXISTING_LABEL : NOT_EXISTING_LABEL}`,
          }, {
            key: 'Keller',
            value: `${apartment.hasCellar ? EXISTING_LABEL : NOT_EXISTING_LABEL}`,
          }, {
            key: 'Aufzug',
            value: `${apartment.hasLift ? EXISTING_LABEL : NOT_EXISTING_LABEL}`,
          }, {
            key: 'Eigengarten',
            value: `${apartment.hasGarden ? EXISTING_LABEL : NOT_EXISTING_LABEL}`,
          }, {
            key: 'Dachgeschosswohnung',
            value: `${apartment.isOnTopFloor ? YES_LABEL : NO_LABEL}`,
          }],
        },
      ],
    };
  }
  if (userData.project.houseData) {
    const house = userData.project.houseData;

    return {
      calculationDate: userData.valuationResult.date,
      categories: [
        {
          headline: 'Adresse',
          parameters: [{
            key: 'Adresse',
            value: `${house.address.street} ${house.address.houseNumber}, ${house.address.postCode} ${house.address.city}`,
          }],
        },
        {
          headline: 'Immobilienbewertung',
          parameters: [{
            key: 'Obergrenze',
            value: `${separateNumberWithPoints(valuation.higherBorder)} EUR`,
          }, {
            key: 'Schätzwert',
            value: `${separateNumberWithPoints(valuation.average)} EUR`,
            bold: true,
          }, {
            key: 'Untergrenze',
            value: `${separateNumberWithPoints(valuation.lowerBorder)} EUR`,
          }],
        },
        {
          headline: 'Objektdaten',
          parameters: [{
            key: 'Immobilientyp',
            value: `${PROPERTY_TYPE_CONFIG[house.type].label}`,
          }, {
            key: 'Baujahr',
            value: `${house.buildYear}`,
          }, {
            key: 'Wohnfläche',
            value: `${house.livingArea} m²`,
          }, {
            key: 'Grundstücksfläche',
            value: `${house.plotArea} m²`,
          }, {
            key: 'Bauzustand',
            value: `${PROPERTY_STATE_LABELS[house.propertyState]}`,
          }, {
            key: 'Loggia',
            value: `${house.hasLoggia ? EXISTING_LABEL : NOT_EXISTING_LABEL}`,
          }, {
            key: 'Balkon',
            value: `${house.hasBalcony ? EXISTING_LABEL : NOT_EXISTING_LABEL}`,
          }, {
            key: 'Terrasse',
            value: `${house.hasTerrace ? EXISTING_LABEL : NOT_EXISTING_LABEL}`,
          }, {
            key: 'Keller',
            value: `${house.hasCellar ? EXISTING_LABEL : NOT_EXISTING_LABEL}`,
          }, {
            key: 'Garage',
            value: `${house.hasGarage ? EXISTING_LABEL : NOT_EXISTING_LABEL}`,
          }],
        },
      ],
    };
  }
  if (userData.project.plotData) {
    const plot = userData.project.plotData;

    return {
      calculationDate: userData.valuationResult.date,
      categories: [
        {
          headline: 'Immobilienbewertung',
          parameters: [{
            key: 'Obergrenze',
            value: `${separateNumberWithPoints(valuation.higherBorder)} EUR`,
          }, {
            key: 'Schätzwert',
            value: `${separateNumberWithPoints(valuation.average)} EUR`,
            bold: true,
          }, {
            key: 'Untergrenze',
            value: `${separateNumberWithPoints(valuation.lowerBorder)} EUR`,
          }],
        },
        {
          headline: 'Objektdaten',
          parameters: [{
            key: 'Immobilientyp',
            value: `${PROPERTY_TYPE_CONFIG[plot.type].label}`,
          }, {
            key: 'Widmung',
            value: `${PLOT_STATE_LABELS[plot.dedication]}`,
          }, {
            key: 'Grundstücksfläche',
            value: `${plot.plotArea} m²`,
          }],
        },
      ],
    };
  }
  return undefined;
};
