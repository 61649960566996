import React from 'react';
import { ReactComponent as SemiDetachedHouseIcon } from '../assets/ic_doppelhaus.svg';
import { ReactComponent as PlotIcon } from '../assets/ic_grundstückskauf.svg';
import { ReactComponent as SingleHouseIcon } from '../assets/ic_hauskauf.svg';
import { ReactComponent as TerracedHouseIcon } from '../assets/ic_reihenhaus.svg';
import { ReactComponent as ApartmentIcon } from '../assets/ic_wohnungskauf.svg';
import { Haustyp } from '../interfaces/immazing-api';

export type PropertyType = 'apartment' | 'single_house' | 'semi_detached_house' | 'terraced_house' | 'plot';

export const PROPERTY_TYPE_CONFIG: {[key in PropertyType]: {label: string; icon: React.ReactNode}} = {
  apartment: {
    label: 'Wohnung',
    icon: <ApartmentIcon></ApartmentIcon>,
  },
  single_house: {
    label: 'Einfamilienhaus',
    icon: <SingleHouseIcon></SingleHouseIcon>,
  },
  semi_detached_house: {
    label: 'Doppelhaushälfte',
    icon: <SemiDetachedHouseIcon></SemiDetachedHouseIcon>,
  },
  terraced_house: {
    label: 'Reihenhaus',
    icon: <TerracedHouseIcon></TerracedHouseIcon>,
  },
  plot: {
    label: 'Grundstück',
    icon: <PlotIcon></PlotIcon>,
  },
};

export const PROPERTY_TYPE_HAUSTYP_MAP: {[key in PropertyType]: Haustyp} = {
  apartment: 'WOHNUNG',
  single_house: 'EINFAMILIENHAUS',
  semi_detached_house: 'REIHENHAUS',
  terraced_house: 'DOPPELHAUSHAELFTE',
  plot: 'GRUNDSTUECK',
};
