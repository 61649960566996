import React, {
  createContext
} from 'react';
import TagManager from 'react-gtm-module';

interface TrackingContextOptions {
  pushToDataLayer(trackingObject: any): void;
}

export const TrackingContext = createContext<TrackingContextOptions>({
  pushToDataLayer: () => { },
});

export const TrackingProvider: React.FC = ({ children }) => {
  const pushToDataLayer = (trackingObject: any) => {
    TagManager.dataLayer({
      dataLayer: {
        ...trackingObject,
      },
    });
  };

  return (
    <TrackingContext.Provider value={{ pushToDataLayer }}>
      {children}
    </TrackingContext.Provider>
  );
};
