import { useEffect, useState } from 'react';

export function useCookieWatcher(intervalInMillis: number): string[] {
  const [cookieGroups, setCookieGroups] = useState(window.OptanonActiveGroups);

  useEffect(() => {
    const interval = window.setInterval(() => setCookieGroups(window.OptanonActiveGroups), intervalInMillis);
    return () => window.clearInterval(interval);
  });

  return cookieGroups;
}
