export class TrackingObjectBuilder {
  trackingObject = {};

  forClick({ eventLabel, eventCategory }: { eventLabel: string, eventCategory: string }) {
    this.trackingObject = {
      event: 'Click',
      eventAction: 'Click',
      eventLabel: eventLabel,
      eventCategory,
    };
    return this;
  }

  forFieldSubmit({
    eventAction,
    eventCategory,
    eventLabel,
    eventValue,
  }: {
    eventAction: string;
    eventCategory: string;
    eventLabel: string;
    eventValue: number;
  }) {
    this.trackingObject = {
      event: 'form',
      eventType: 'submit',
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
    };
    return this;
  }

  build() {
    return this.trackingObject;
  }
}