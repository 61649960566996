import { separateNumberWithPoints } from '../general/separate-numbers-with-points';

interface Range {
  min: number;
  max: number;
  step: number;
}

export const categorizeAndStringifyValuationResult = (result: number, { min, max, step }: Range): string => {
  if (result <= min) return `<= ${separateNumberWithPoints(min)} €`;
  if (result >= max) return `>= ${separateNumberWithPoints(max)} €`;

  for (let currentStepIndex = 0; currentStepIndex < max / step; currentStepIndex += 1) {
    if (result < min + 1 + step * currentStepIndex) {
      const lower = min + 1 + step * currentStepIndex - step;
      const higher = min + step * currentStepIndex;
      return `${separateNumberWithPoints(lower)} - ${separateNumberWithPoints(higher)} €`;
    }
    if (result > max - step && result < max) {
      const lower = max - step + 1;
      const higher = max - 1;
      return `${separateNumberWithPoints(lower)} - ${separateNumberWithPoints(higher)} €`;
    }
  }

  return `${separateNumberWithPoints(result)} €`;
};
