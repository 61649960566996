import React, { useContext } from 'react';
import {
  Icon, Paragraph, Section, Button, ButtonContainer, Headline, HeroImage, Separator,
} from '@virtualidentityag/rbi-ui-components';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as House } from '../../assets/searchhouse.svg';
import { ReactComponent as SendCalculation } from '../../assets/sendcalculation.svg';
import { ReactComponent as Thumbsup } from '../../assets/thumbsup.svg';
import { TrackingContext } from '../../contexts/tracking-context';
import { TrackingObjectBuilder } from '../../helpers/tracking/tracking-object-builder';

import heroImageDesktop from '../../assets/img/hero_welcome_desktop.jpg';
import heroImageMobile from '../../assets/img/hero_welcome_mobile.jpg';

import { history } from '../../helpers/general/history';

export const WelcomeScreen = () => {
  const { pushToDataLayer } = useContext(TrackingContext);
  return (
  <React.Fragment>
    <HeroImage
      button={<Button onButtonClick={() => {

        pushToDataLayer(new TrackingObjectBuilder().forClick({
          eventLabel: 'Jetzt starten',
          eventCategory: 'button',
        }).build());

        history.push('/property-type-questionnaire');
      }}>Jetzt ImmoCheck starten</Button>}
      caption={
        <React.Fragment>Schnell und unkompliziert<br />zu Ihrem Schätzwert</React.Fragment>
      }
      figcaption='Sie haben Ihre Wunschimmobilie schon im Blick?'
      srcDesktop={heroImageDesktop}
      srcMobile={heroImageMobile}
    ></HeroImage>
    <Container maxWidth='md' disableGutters>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Section withMargin>
            <Grid container justify='center' spacing={3}>
              <Grid item md={8} xs={12}>
                <Headline centered level={2} >
                    In nur drei Schritten zum Schätzwert Ihres WohnTraums
                </Headline>
                <Icon subline='Angaben zum WohnTraum'>
                  <House />
                </Icon>
                <Paragraph withoutMarginTop centered>
                  Sie haben Ihre Wunschimmobilie gefunden? Mit dem ImmoCheck der Raiffeisen Bausparkasse können Sie online den Schätzwert Ihrer Wunschimmobilie ermitteln - mit nur wenigen Angaben und völlig kostenlos. Denn große Entscheidungen trifft man am besten mit einem guten Gefühl.
                </Paragraph>
                <Icon subline='Ermitteln des Schätzwerts'>
                  <SendCalculation />
                </Icon>
                <Paragraph withoutMarginTop centered>
                  Binnen weniger Sekunden erhalten Sie per E-Mail das Ergebnis Ihres ImmoChecks. Hierbei greifen wir auf aktuelle Marktdaten zurück und achten selbstverständlich auf die datenschutzkonforme Verarbeitung Ihrer Daten.
                </Paragraph>
                <Icon subline='WohnTraum berechnen'>
                  <Thumbsup />
                </Icon>
                <Paragraph withoutMarginTop centered>
                  Sie wollen wissen wie Sie Ihre Wunschimmobilie finanzieren können? Der WohnTraumRechner der Raiffeisen Bausparkasse gibt Ihnen die Möglichkeit, Ihren WohnTraum zu planen, zu berechnen und schnell umzusetzen - auf Wunsch mit einer vorläufigen Finanzierungszusage!
                </Paragraph>
              </Grid>
            </Grid>
          </Section>
          <Section transparent withMargin>
            <Grid container justify='center' spacing={3}>
              <Grid item md={10} xs={12}>
                <Separator variant='curly' />
                <Headline centered level={2} >
                    In Bestzeit zum ImmoCheck
                </Headline>
                <Paragraph centered>
                  Mit dem ImmoCheck der Raiffeisen Bausparkasse ermitteln Sie schnell und unkompliziert den Schätzwert Ihrer Wunschimmobilie.
                </Paragraph>
                <Separator variant='curly' />
                <ButtonContainer>
                  <Button onButtonClick={() => {
                    pushToDataLayer(new TrackingObjectBuilder().forClick({
                      eventCategory: 'button',
                      eventLabel: 'Jetzt starten',
                    }).build());
                    history.push('/property-type-questionnaire');
                  }
                  }
                  >Jetzt ImmoCheck starten</Button>
                </ButtonContainer>
              </Grid>
            </Grid>
          </Section>
        </Grid>
      </Grid>
    </Container>
  </React.Fragment>
)};
