import React, {
  createContext, useState, Dispatch, SetStateAction,
} from 'react';

export interface PropertyExtras {
  hasLoggia: boolean;
  hasGarden: boolean;
  hasLift: boolean;
  hasTerrace: boolean;
  hasBalcony: boolean;
  hasCellar: boolean;
  hasAttic: boolean;
  hasGarage: boolean;
}

export interface QuestionnaireContextOptions {
  propertyType: string;
  setPropertyType: Dispatch<SetStateAction<string>>;
  street: string;
  setStreet: Dispatch<SetStateAction<string>>;
  houseNumber: string;
  setHouseNumber: Dispatch<SetStateAction<string>>;
  postCode: number;
  setPostCode: Dispatch<SetStateAction<number>>;
  city: string;
  setCity: Dispatch<SetStateAction<string>>;
  latitude: number;
  setLatitude: Dispatch<SetStateAction<number>>;
  longitude: number;
  setLongitude: Dispatch<SetStateAction<number>>;
  livingArea: string;
  setLivingArea: Dispatch<SetStateAction<string>>;
  plotArea: string;
  setPlotArea: Dispatch<SetStateAction<string>>;
  floor: string;
  setFloor: Dispatch<SetStateAction<string>>;
  buildYear: string;
  setBuildYear: Dispatch<SetStateAction<string>>;
  propertyState: string;
  setPropertyState: Dispatch<SetStateAction<string>>;
  plotState: string;
  setPlotState: Dispatch<SetStateAction<string>>;
  extras: PropertyExtras;
  setExtras: Dispatch<SetStateAction<PropertyExtras>>;
  stepOptions: StepOptions;
  setStepOptions: Dispatch<SetStateAction<StepOptions>>;
}

export interface StepOptions {
  step: number;
  hideStepper: boolean;
}

export const QuestionnaireContext = createContext<QuestionnaireContextOptions>({
  propertyType: '',
  setPropertyType: () => { },
  street: '',
  setStreet: () => { },
  houseNumber: '',
  setHouseNumber: () => { },
  postCode: 0,
  setPostCode: () => { },
  city: '',
  setCity: () => {},
  latitude: 0,
  setLatitude: () => {},
  longitude: 0,
  setLongitude: () => {},
  livingArea: '',
  setLivingArea: () => { },
  plotArea: '',
  setPlotArea: () => { },
  floor: '',
  setFloor: () => { },
  buildYear: '',
  setBuildYear: () => { },
  propertyState: '',
  setPropertyState: () => { },
  plotState: '',
  setPlotState: () => { },
  extras: {
    hasLoggia: false,
    hasGarden: false,
    hasLift: false,
    hasTerrace: false,
    hasBalcony: false,
    hasCellar: false,
    hasAttic: false,
    hasGarage: false,
  },
  setExtras: () => { },
  stepOptions: {
    step: 0,
    hideStepper: false,
  },
  setStepOptions: () => { },
});

export const QuestionnaireProvider: React.FC = ({ children }) => {
  const [propertyType, setPropertyType] = useState('');
  const [street, setStreet] = useState('');
  const [houseNumber, setHouseNumber] = useState('');
  const [postCode, setPostCode] = useState(0);
  const [city, setCity] = useState('');
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [livingArea, setLivingArea] = useState('');
  const [plotArea, setPlotArea] = useState('');
  const [floor, setFloor] = useState('');
  const [buildYear, setBuildYear] = useState('');
  const [propertyState, setPropertyState] = useState('');
  const [plotState, setPlotState] = useState('');
  const [extras, setExtras] = useState({
    hasLoggia: false,
    hasGarden: false,
    hasLift: false,
    hasTerrace: false,
    hasBalcony: false,
    hasCellar: false,
    hasAttic: false,
    hasGarage: false,
  });
  const [stepOptions, setStepOptions] = useState({
    step: 0,
    hideStepper: false,
  });

  return (
    <QuestionnaireContext.Provider value={{
      propertyType, setPropertyType, street, setStreet, houseNumber, setHouseNumber, postCode, setPostCode, city, setCity, latitude, setLatitude, longitude, setLongitude, livingArea, setLivingArea, plotArea, setPlotArea, floor, setFloor, buildYear, setBuildYear, propertyState, setPropertyState, plotState, setPlotState, extras, setExtras, stepOptions, setStepOptions,
    }}>
      {children}
    </QuestionnaireContext.Provider>
  );
};
