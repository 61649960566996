/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://lk3jxgabc5dfpatw526sb67vy4.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-rnszaqlyk5ff7bo2skpv76vrva",
    "aws_content_delivery_bucket": "rbi-immo-check-app-20221213105326-hostingbucket-master",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://dsnutctigjp4t.cloudfront.net"
};


export default awsmobile;
