import { API, graphqlOperation } from 'aws-amplify';
import { fetchDate } from './fetch-date';
import { getIpAccess } from '../../graphql/queries';
import { createIpAccess, updateIpAccess } from '../../graphql/mutations';

export const addIpAttemptToDb = async (ipAddress: string): Promise<void> => {
  const ipAccess: any = await API.graphql(graphqlOperation(getIpAccess, { id: ipAddress }));
  const currentDate = await fetchDate();
  if (!ipAccess.data.getIpAccess) {
    await API.graphql(graphqlOperation(createIpAccess, { input: { id: ipAddress, accessDates: [currentDate] } }));
  } else {
    const { accessDates } = ipAccess.data.getIpAccess;
    await API.graphql(graphqlOperation(updateIpAccess, { input: { id: ipAddress, accessDates: [...accessDates, currentDate] } }));
  }
};
