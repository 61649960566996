/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getIpAddress = /* GraphQL */ `
  query GetIpAddress {
    getIpAddress
  }
`;
export const sendCalculationResultEmail = /* GraphQL */ `
  query SendCalculationResultEmail($userData: AWSJSON!) {
    sendCalculationResultEmail(userData: $userData)
  }
`;
export const getCurrentDate = /* GraphQL */ `
  query GetCurrentDate {
    getCurrentDate
  }
`;
export const getPropertyValuation = /* GraphQL */ `
  query GetPropertyValuation($propertyInformation: AWSJSON!) {
    getPropertyValuation(propertyInformation: $propertyInformation)
  }
`;
export const getIpAccess = /* GraphQL */ `
  query GetIpAccess($id: ID!) {
    getIpAccess(id: $id) {
      id
      accessDates
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listIpAccesss = /* GraphQL */ `
  query ListIpAccesss(
    $filter: ModelIpAccessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIpAccesss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accessDates
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmailAccess = /* GraphQL */ `
  query GetEmailAccess($id: ID!) {
    getEmailAccess(id: $id) {
      id
      accessDates
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmailAccesss = /* GraphQL */ `
  query ListEmailAccesss(
    $filter: ModelEmailAccessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailAccesss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accessDates
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
