import { TrackingObjectBuilder } from '../../helpers/tracking/tracking-object-builder';
import { Base64 } from 'js-base64';
import { QuestionnaireContextOptions } from '../../contexts/questionnaire-context';
import { SubmissionFormData } from './index';
import { fetchIpAddress } from '../../helpers/limitation-api/fetch-ip-address';
import { fetchPropertyValuation } from '../../helpers/immazing-api';
import { PROPERTY_TYPE_HAUSTYP_MAP, PropertyType } from '../../constants/property-types';
import { PROPERTY_STATE_ZUSTAND_ART_MAP, PropertyState } from '../../constants/property-states';
import { PLOT_STATE_SUBTYPE_GST_MAP, PlotState } from '../../constants/plot-states';
import { UserData } from '../../interfaces/user-data';
import { requestCalculationEmail } from '../../helpers/emails/request-calculcation-email';
import { buildUrlWithParams } from '../../helpers/general/build-url-with-params';
import { getDomainForEnvironment } from '../../constants';
import { Environment } from '../../interfaces/env';
import { ImmazingApiInput } from '../../interfaces/immazing-api';
import { checkIfIpOverLimit } from '../../helpers/limitation-api/check-if-ip-over-limit';
import { checkIfEmailOverLimit } from '../../helpers/limitation-api/check-if-email-over-limit';
import { addIpAttemptToDb } from '../../helpers/limitation-api/add-ip-attempt-to-db';
import { addEmailAttemptToDb } from '../../helpers/limitation-api/add-email-attempt-to-db';
import { categorizeAndStringifyValuationResult } from '../../helpers/tracking/categorize-and-stringify-valuation-result';

export const submitData = async (
{
  pushToDataLayer,
  onSubmitted,
  onLimitExceeded,
  submissionFormData,
  questionnaireData,
}: {
  pushToDataLayer: (trackingObject: any) => void;
  onSubmitted: (emailAddress: string) => void;
  onLimitExceeded: () => void;
  submissionFormData: SubmissionFormData;
  questionnaireData: QuestionnaireContextOptions;
}
) => {
  const ipAddress = await fetchIpAddress();
  const isIpOverLimit = await checkIfIpOverLimit(ipAddress);
  const isEmailOverLimit = await checkIfEmailOverLimit(submissionFormData.email);

  if (!isIpOverLimit && !isEmailOverLimit) {
    await addIpAttemptToDb(ipAddress);
    await addEmailAttemptToDb(submissionFormData.email);

    const valuationInput: ImmazingApiInput = {
      baujahr: parseInt(questionnaireData.buildYear, 10),
      firma: 'Raiffeisen Bausparkasse',
      grundstuecksflaeche: parseInt(questionnaireData.plotArea, 10),
      wohnflaeche: parseInt(questionnaireData.livingArea, 10),
      etage_nr: questionnaireData.floor ? parseInt(questionnaireData.floor, 10) : 0,
      anzahl_terrassen: questionnaireData.extras.hasTerrace ? 1 : 0,
      anzahl_balkone: questionnaireData.extras.hasBalcony ? 1 : 0,
      anzahl_logia: questionnaireData.extras.hasLoggia ? 1 : 0,
      cell: questionnaireData.extras.hasCellar ? 1 : 0,
      garten: questionnaireData.extras.hasGarden ? 1 : 0,
      garage: questionnaireData.extras.hasGarage ? 1 : 0,
      etage_dg: questionnaireData.extras.hasAttic ? 1 : 0,
      lift: questionnaireData.extras.hasLift ? 1 : 0,
      haustyp: PROPERTY_TYPE_HAUSTYP_MAP[questionnaireData.propertyType as PropertyType],
      zustand_art: PROPERTY_STATE_ZUSTAND_ART_MAP[questionnaireData.propertyState as PropertyState],
      lat: questionnaireData.latitude,
      lon: questionnaireData.longitude,
      ...(questionnaireData.plotState && { subtype_gst: PLOT_STATE_SUBTYPE_GST_MAP[questionnaireData.plotState as PlotState] }),
      error_on: 1,
    };

    const valuationResult = await fetchPropertyValuation(valuationInput);

    if (valuationResult.exists_reason) {
      pushToDataLayer(new TrackingObjectBuilder().forFieldSubmit({
        eventCategory: 'Valuation',
        eventAction: 'Error',
        eventLabel: 'ImmAzing API Error',
        eventValue: 0,
      }).build());
      throw new Error();
    }

    pushToDataLayer(new TrackingObjectBuilder().forFieldSubmit({
      eventCategory: 'Valuation',
      eventAction: 'Result',
      eventLabel: `${categorizeAndStringifyValuationResult(valuationResult.vw, {
        min: 100000,
        max: 1000000,
        step: 100000,
      })}`,
      eventValue: Math.floor(valuationResult.vw),
    }).build());

    const resultUserData: UserData = {
      personal: {
        firstName: submissionFormData.firstName,
        lastName: submissionFormData.lastName,
      },
      project: {
        apartmentData: questionnaireData.propertyType === 'apartment' ? {
          buildYear: questionnaireData.buildYear,
          address: {
            street: questionnaireData.street,
            houseNumber: questionnaireData.houseNumber,
            postCode: questionnaireData.postCode,
            city: questionnaireData.city,
            longitude: questionnaireData.longitude,
            latitude: questionnaireData.latitude,
          },
          propertyState: (questionnaireData.propertyState as PropertyState),
          plotArea: questionnaireData.plotArea,
          livingArea: questionnaireData.livingArea,
          floor: questionnaireData.floor,
          hasBalcony: questionnaireData.extras.hasBalcony,
          hasCellar: questionnaireData.extras.hasCellar,
          hasLift: questionnaireData.extras.hasLift,
          hasGarage: questionnaireData.extras.hasGarage,
          hasGarden: questionnaireData.extras.hasGarden,
          hasLoggia: questionnaireData.extras.hasLoggia,
          hasTerrace: questionnaireData.extras.hasTerrace,
          isOnTopFloor: questionnaireData.extras.hasAttic,
          type: (questionnaireData.propertyType as PropertyType),
        } : undefined,
        houseData: questionnaireData.propertyType === 'single_house' || questionnaireData.propertyType === 'semi_detached_house' || questionnaireData.propertyType === 'terraced_house' ? {
          buildYear: questionnaireData.buildYear,
          address: {
            street: questionnaireData.street,
            houseNumber: questionnaireData.houseNumber,
            postCode: questionnaireData.postCode,
            city: questionnaireData.city,
            longitude: questionnaireData.longitude,
            latitude: questionnaireData.latitude,
          },
          propertyState: (questionnaireData.propertyState as PropertyState),
          plotArea: questionnaireData.plotArea,
          livingArea: questionnaireData.livingArea,
          hasBalcony: questionnaireData.extras.hasBalcony,
          hasCellar: questionnaireData.extras.hasCellar,
          hasGarage: questionnaireData.extras.hasGarage,
          hasLoggia: questionnaireData.extras.hasLoggia,
          hasTerrace: questionnaireData.extras.hasTerrace,
          type: (questionnaireData.propertyType as PropertyType),
        } : undefined,
        plotData: questionnaireData.propertyType === 'plot' ? {
          type: (questionnaireData.propertyType as PropertyType),
          address: {
            street: questionnaireData.street,
            houseNumber: questionnaireData.houseNumber,
            postCode: questionnaireData.postCode,
            city: questionnaireData.city,
            longitude: questionnaireData.longitude,
            latitude: questionnaireData.latitude,
          },
          dedication: (questionnaireData.plotState as PlotState),
          plotArea: questionnaireData.plotArea,
        } : undefined,
      },
      valuationResult: {
        average: Math.ceil(valuationResult.vw),
        lowerBorder: Math.ceil(valuationResult.vw_lwr),
        higherBorder: Math.ceil(valuationResult.vw_upr),
        date: new Date().toLocaleString('de-de').replace(/[^ -~]/g, ''),
      },
    };

    await requestCalculationEmail({
      email: submissionFormData.email,
      lastName: submissionFormData.lastName,
      gender: submissionFormData.gender,
      redirectUrl: buildUrlWithParams(`${getDomainForEnvironment((process.env.REACT_APP_STAGE as Environment))}/result`, {
        'valuation-data': Base64.encode(JSON.stringify(resultUserData)),
      }),
    });
    pushToDataLayer(new TrackingObjectBuilder().forFieldSubmit({
      eventCategory: 'Valuation',
      eventAction: 'Success',
      eventLabel: 'Successful Submission',
      eventValue: 0,
    }).build());
    onSubmitted(submissionFormData.email);
  } else {
    pushToDataLayer(new TrackingObjectBuilder().forFieldSubmit({
      eventCategory: 'Valuation',
      eventAction: 'Blocked',
      eventLabel: 'User Limit Exceeded',
      eventValue: 0,
    }).build());
    onLimitExceeded();
  }
};
