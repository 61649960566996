import { SubtypeGst } from '../interfaces/immazing-api';

export type PlotState = 'building_land' | 'grassland';

export const PLOT_STATE_LABELS: {[key in PlotState]: string} = {
  building_land: 'Bauland',
  grassland: 'Grünland',
};

export const PLOT_STATE_SUBTYPE_GST_MAP: {[key in PlotState]: SubtypeGst} = {
  building_land: 'gst_bl',
  grassland: 'gst_gl',
};
