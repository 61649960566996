import {Container, Grid} from '@material-ui/core';
import React, {useContext, useEffect, useRef} from 'react';

import {
  Button,
  ButtonContainer,
  GoogleMaps,
  Headline,
  Icon,
  InlineError,
  Paragraph,
  Section,
  SingleSelect, SingleSelectOption,
} from '@virtualidentityag/rbi-ui-components';
import {useForm} from 'react-hook-form';
import {PROPERTY_TYPE_CONFIG, PropertyType} from '../../constants/property-types';
import {QuestionnaireContext} from '../../contexts/questionnaire-context';
import {TrackingContext} from '../../contexts/tracking-context';
import {history} from '../../helpers/general/history';
import {TrackingObjectBuilder} from '../../helpers/tracking/tracking-object-builder';

import {useCookieWatcher} from '../../helpers/general/use-cookie-watcher';
import './styles.scss';

export const PROPERTY_TYPE_QUESTIONNAIRE_LABELS = {
  headline: 'Erzählen Sie uns mehr über Ihren WohnTraum.',
  propertyTypePrompt: 'Wählen Sie bitte die Art Ihrer Wunschimmobilie.',
  addressPrompt: (
    <React.Fragment>
      Geben Sie bitte die genaue Adresse Ihrer Wunschimmobilie in Österreich ein. <br />{' '}
      <b>Hinweis:</b> Sollte die Adresse Ihrer Wunschimmobilie nicht gefunden
      werden können, geben Sie bitte die nächstmögliche Hausnummer/Straße ein.
    </React.Fragment>
  ),
};

interface PTQFormData {
  propertyType: string;
  street: string;
  houseNumber: string;
  postCode: number;
  city: string;
  latitude: number;
  longitude: number;
}

declare global {
  interface Window {
    OneTrust: any;
    OptanonActiveGroups: any;
  }
}

export const PropertyTypeQuestionnaireScreen: React.FC = () => {
  const {
    propertyType,
    setPropertyType,
    street,
    setStreet,
    houseNumber,
    setHouseNumber,
    postCode,
    setPostCode,
    city,
    setCity,
    latitude,
    setLatitude,
    longitude,
    setLongitude,
    setLivingArea,
    setPlotArea,
    setFloor,
    setBuildYear,
    setPropertyState,
    setPlotState,
    setExtras,
    setStepOptions,
  } = useContext(QuestionnaireContext);
  const {
    handleSubmit, register, errors, setValue, watch, formState, triggerValidation,
  } = useForm<PTQFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      propertyType,
      street,
      houseNumber,
      postCode,
      city,
      latitude,
      longitude,
    },
  });
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    setStepOptions({
      step: 0,
      hideStepper: false,
    });
  }, [setStepOptions]);

  useEffect(() => {
    register({ name: 'propertyType' }, { required: true });
    register({ name: 'street' }, { required: true, pattern: /^(?!\s*$).+/ });
    register({ name: 'houseNumber' }, { required: true, pattern: /^(?!\s*$).+/ });
    register({ name: 'postCode' }, { required: true });
    register({ name: 'city' }, { required: true, pattern: /^(?!\s*$).+/ });
    register({ name: 'latitude' }, { required: true });
    register({ name: 'longitude' }, { required: true });
  }, [register]);

  useEffect(() => {
    if (formRef !== null && formRef.current !== null) {
      formRef.current.addEventListener('keydown', (e) => {
        if (e.keyCode === 13) { e.preventDefault(); }
      });
    }
  }, []);

  const cookieGroups = useCookieWatcher(50);

  const { pushToDataLayer } = useContext(TrackingContext);

  const resetQuestionnaireContext = () => {
    setLivingArea('');
    setPlotArea('');
    setFloor('');
    setBuildYear('');
    setPropertyState('');
    setPlotState('');
    setExtras({
      hasLoggia: false,
      hasGarden: false,
      hasLift: false,
      hasTerrace: false,
      hasBalcony: false,
      hasCellar: false,
      hasAttic: false,
      hasGarage: false,
    });
  };

  const onFormSubmit = ({
    propertyType, street, houseNumber, postCode, city, latitude, longitude,
  }: PTQFormData) => {
    setPropertyType(propertyType);
    setStreet(street);
    setHouseNumber(houseNumber);
    setPostCode(postCode);
    setCity(city);
    setLatitude(latitude);
    setLongitude(longitude);
    pushToDataLayer(new TrackingObjectBuilder().forFieldSubmit({
      eventCategory: 'ProjectDetails',
      eventAction: 'Type',
      eventLabel: propertyType,
      eventValue: 0,
    }).build());

    pushToDataLayer(new TrackingObjectBuilder().forFieldSubmit({
      eventCategory: 'ProjectDetails',
      eventAction: 'Zipcode',
      eventLabel: `${postCode}`,
      eventValue: 0,
    }).build());

    history.push('/property-details-questionnaire');
  };

  const _propertyType = watch('propertyType');

  return (
    <div className='property-type-questionnaire'>
      <Container maxWidth='md' disableGutters>
        <Grid container spacing={3} justify='center'>
          <Grid item xs={12} md={9}>
            <form ref={formRef} onSubmit={handleSubmit((data) => onFormSubmit(data))}>
              <Section transparent>
                <Headline noMargin level={2}>
                  {PROPERTY_TYPE_QUESTIONNAIRE_LABELS.headline}
                </Headline>
                <Paragraph>
                  {PROPERTY_TYPE_QUESTIONNAIRE_LABELS.propertyTypePrompt}
                </Paragraph>
                <SingleSelect>
                  {Object.keys(PROPERTY_TYPE_CONFIG).map((key) => (
                    <SingleSelectOption
                      key={`singleSelectKey-${key}`}
                      selectKey={key}
                      isToggled={key === _propertyType}
                      width={50}
                      onOptionSelect={(selectedKey) => {
                        setValue('propertyType', selectedKey);
                        resetQuestionnaireContext();
                        if (formState.isSubmitted) {
                          triggerValidation();
                        }
                      }}
                    >
                      <Icon subline={PROPERTY_TYPE_CONFIG[key as PropertyType].label}>{PROPERTY_TYPE_CONFIG[key as PropertyType].icon}</Icon>
                    </SingleSelectOption>
                  ))}
                </SingleSelect>
                <Paragraph>
                  {PROPERTY_TYPE_QUESTIONNAIRE_LABELS.addressPrompt}
                </Paragraph>
                {/* {( false && !cookieGroups?.includes('R0006')) ? (
                  <Paragraph className='error-paragraph'>
                    Um Google Maps zu nutzen, müssen Sie "personalisierte Werbung & Statistik" zustimmen. Bitte klicken
                    Sie dafür auf "COOKIES EINSTELLEN".
                  </Paragraph>
                ) : ( */}
                  <GoogleMaps
                    defaultInputValue={street ? `${street} ${houseNumber}, ${city}` : undefined}
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
                    defaultCenter={{
                      latitude: latitude || 48.20858275,
                      longitude: longitude || 16.37390625,
                    }}
                    allowedCountryCodes={['at']}
                    onSelectAddress={(address) => {
                      if (address.street) {
                        setValue('street', address.street);
                      } else {
                        setValue('street', '');
                      }
                      if (address.houseNumber) {
                        setValue('houseNumber', address.houseNumber);
                      } else {
                        setValue('houseNumber', '');
                      }
                      if (address.postCode) {
                        setValue('postCode', address.postCode);
                      }
                      if (address.city) {
                        setValue('city', address.city);
                      } else {
                        setValue('city', '');
                      }
                      if (address.latitude) {
                        setValue('latitude', address.latitude);
                      }
                      if (address.longitude) {
                        setValue('longitude', address.longitude);
                      }
                    }}
                  />
                {/* )} */}
                <div className='cookie-button-div' style={{ display: cookieGroups?.includes('R0006') ? 'none' : 'block' }}>
                  <Button theme='transparent' onButtonClick={() => window.OneTrust.ToggleInfoDisplay()}>Cookies einstellen</Button>
                </div>
                {errors.propertyType
                  ? <InlineError>
                    Bitte wählen Sie die Art Ihrer Wunschimmobilie.
                  </InlineError> : ''
                }
                {errors.longitude || errors.latitude || errors.street || errors.houseNumber || errors.city
                  ? <InlineError>
                    Bitte geben Sie eine Adresse mit Straße, Hausnummer und Ort an.
                  </InlineError> : ''
                }
                <ButtonContainer>
                  <Button type='submit'>weiter</Button>
                  <Button
                    theme='secondary'
                    onButtonClick={() => history.push('/')}
                  >
                    zurück
                  </Button>
                </ButtonContainer>
              </Section>
            </form>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
